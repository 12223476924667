<template>
  <svg
    height="800px"
    width="800px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 297 297"
    xml:space="preserve">
    <g>
      <g>
        <g>
          <g>
            <circle
              style="fill:#32373B;"
              cx="148.5"
              cy="148.5"
              r="148.5"/>
          </g>
        </g>
      </g>
      <path
        style="fill:#262626;"
        d="M175.442,50.702l-64.915,217.929l28.029,28.029c3.288,0.217,6.601,0.339,9.944,0.339c74.495,0,136.174-54.856,146.858-126.376L175.442,50.702z"/>
      <g>
        <path
          style="fill:#BDC3C7;"
          d="M148.5,191.5L148.5,191.5c-20.987,0-38-17.013-38-38v-76c0-20.987,17.013-38,38-38l0,0c20.987,0,38,17.013,38,38v76C186.5,174.487,169.487,191.5,148.5,191.5z"/>
      </g>
      <g>
        <path
          style="fill:#9DA2A5;"
          d="M148.667,39.504v151.992c20.91-0.09,37.833-17.065,37.833-37.996v-76C186.5,56.569,169.577,39.595,148.667,39.504z"/>
      </g>
      <path
        style="fill:#9ADAD9;"
        d="M221.5,148.5c0-3.866-3.134-7-7-7s-7,3.134-7,7c0,32.532-26.468,59-59,59s-59-26.468-59-59c0-3.866-3.134-7-7-7s-7,3.134-7,7c0,37.89,29.021,69.121,66,72.657V257h-26c-3.866,0-7,3.134-7,7s3.134,7,7,7h66c3.866,0,7-3.134,7-7s-3.134-7-7-7h-26v-35.843C192.479,217.621,221.5,186.39,221.5,148.5z"/>
      <path
        style="fill:#74AFAC;"
        d="M221.5,148.5c0-3.866-3.134-7-7-7s-7,3.134-7,7c0,32.476-26.378,58.905-58.833,58.996V271H181.5c3.866,0,7-3.134,7-7s-3.134-7-7-7h-26v-35.843C192.479,217.621,221.5,186.39,221.5,148.5z"/>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
