<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    clipped
    class="navigation__container"
    app>
    <v-list-item class="navigation__container--header">
      <v-list-item-content>
        <v-list-item-title class="text-h4 clients_head">
          <clients-icon></clients-icon> <p>Clientes</p>
        </v-list-item-title>
        <!-- <v-badge v-if="monitored_company.pending_events > 0" color="red" :content="monitored_company.pending_events">{{ monitored_company.name }}</v-badge> -->
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <template>
      <v-list>
        <v-list-group
        v-for="monitored_company in getMonitoredCompanies()" 
        :key="monitored_company.id">
            <template v-slot:activator>
              <v-list-item>{{ monitored_company.name }}</v-list-item>
            </template>

            <v-list-item v-for="(location, l) in monitored_company.assigned_locations" 
            :key="l" @click="changeRoute(monitored_company.id, monitored_company, location.id)">
              <v-list-item-content :title="location.name" class="navigation__container--item_content">
                <v-list-item-title>{{ location.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
        </v-list-group>
      </v-list>
    </template>

  </v-navigation-drawer>
</template>

<script>
// import CircleIcon from "@/components/shared/icons/CircleIcon.vue";
// import ArrowCircleRightIcon from "@/components/shared/icons/ArrowCircleRightIcon.vue";
  export default {
    data: () => ({
      drawer: null,
      selected_item:'',
      selected_subitem:'',
      current_name: 'Compañías Monitoreadas',
      items: [
        {
          title: 'Compañías Monitoreadas',
          divider: true,
          principal_route: '',
          section: 'companys',
          id: 0
        },
      ]
    }),
    components: {
      // "circle-icon" : CircleIcon,
      // "arrow-circle-right-icon" : ArrowCircleRightIcon
    },
    watch: {
      selected_item: function() {
        if(this.selected_item==='classnav'){
          this.current_name = 'Clases';
        }
      }
    },
    mounted(){},
    methods:{
      changeRoute(index, company, location_id){
        if (this.$route.params.company_id !== company.id || this.$route.params.location_id !== location_id){
          this.updateSelectedCompany(index)
          this.validateSections(company)
          // this.updateShowCompanySections(false)
          if(!this.getInCallState()){
            this.$router.push({name: 'interaction', params: {company_id: company.id, location_id: location_id}})
          }
        }
      }
    }
  }
</script>
