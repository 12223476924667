<template>
	<article class="monitoring__main_section">
		<div class="monitoring__drawer">
			<v-list-item class="monitoring__container--header">
				<v-list-item-content>
					<v-list-item-title
						class="text-h4 clients_head"
						title="Eventos">
						<component v-bind:is="getCurrentSection().icon"></component>
						<p>Eventos</p>
						<div class="clients_head__notification" v-if="getPendingLocations().length> 0">
              <span>{{ getPendingLocations().length}}</span>
            </div>
					</v-list-item-title>
				</v-list-item-content>
				<company-sections v-if="getShowCompanySections()"></company-sections>
			</v-list-item>
			<v-divider></v-divider>
			<v-list class="monitoring__locations_container">
				<v-list-item
					v-for="(location, i) in getPendingLocations()"
					:key="i"
					@click="selectLocation(location.id, location.company_id)"
					class="monitoring__container--item"
					:class="[location.pending_events > 0 ? 'alert' : 'normal',
									location.id === selected_location && location.pending_events > 0 ? 'selected_alert': location.id === selected_location && location.pending_events === 0 ? 'selected' :'',
									findLocationInEvents(location.id) ? 'new_event_row' : '']">
					<v-list-item-content>
						<v-list-item-title :title="location.name">
							<v-row
								no-gutters
								class="monitoring__container--item_content">
								<v-col
									class="monitoring__container--item_title"
									:class="{'new_event_color': findLocationInEvents(location.id)}">
									{{ location.name }}
								</v-col>
								<v-col cols="auto">
									<arrow-circle-right-icon
										width="30"
										height="30"
										v-if="location.id === selected_location"
										:color="'#4173c0'"></arrow-circle-right-icon>
									<circle-icon
										width="30"
										height="30"
										:color="'#4173c0'"
										v-else-if="location.pending_events > 0 && !findLocationInEvents(location.id)"
										:class="[findLocationInEvents(location.id) ? 'new_event' : '']"></circle-icon>
								</v-col>
							</v-row>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</div>
		<div class="monitoring__events_panel">
			<div class="monitoring__events_header">
				<div class="monitoring__events_header--info_section">
					<span v-if="selected_location !== null">{{ selected_location_name }} - ({{ selected_company_name }})</span>
				</div>
				<div class="monitoring__events_header--date_time_section">
					<span>{{ date }}</span><span>{{ time }}</span>
				</div>
			</div>
			<div class="monitoring__events_management">
				<div class="monitoring__event_section" v-if="show_event_preview">
					<div class="monitoring__event_video_container">
						<event-loader
							v-if="show_event_loader"
							:eventImage="event_image"
							:fastSpeed="fast_speed_event_loader"/>

						<div class="monitoring__event_error" v-show="video_event_error">
							<span class="monitoring__event_error--message" >
								No pudo ser descargado el video del evento.
							</span>
							<v-btn
								rounded
								class="monitoring__event_error--retry_btn"
								@click="clearView(); getEventMediaFiles()">
								reintentar
							</v-btn>
						</div>

						<video-player
							v-if="show_video_player"
							:options="video_options"
							:expandedEvent="expanded_event"
							@close-full-screen="expanded_event = false"/>
					</div>
					<div v-if="selected_event != null && show_event_procedure_form" class="monitoring__event_procedure">
						<div class="monitoring__event_procedure--header">
							<p>Tareas realizadas</p>
						</div>
						<div class="monitoring__event_procedure--form">
							<div class="monitoring__event_procedure--checkbox_group">
								<div
									v-for="(action, index) in event_actions_list"
									:key="index"
									:class="{
										'monitoring__event_procedure--checkbox' : true,
										'checked' : event_procedure.actions_taken.includes(action.text),
										'active' : selected_event.event_state !== 'REVIEWED'
									}"
									@click="toggleActionTaken(action.text)">
									<span
										class="material-icons"
										:class="'checkbox_'+action.color"
										:style="!event_procedure.actions_taken.includes(action.text) ? 'background-color: transparent' : ''">
										{{ event_procedure.actions_taken.includes(action.text) ? 'done' : '' }}
									</span>
									<p>
										{{ action.text }}
									</p>
								</div>
							</div>
							<textarea
								class="monitoring__event_procedure--textarea"
								placeholder="Comentarios..."
								:style="selected_event.event_state == 'REVIEWED' ? 'height:42%' : 'height:28%'"
								:disabled="selected_event.event_state == 'REVIEWED'"
								v-model="event_procedure.comments">
							</textarea>
							<div
								class="monitoring__event_procedure--action_buttons"
								v-if="selected_event.event_state !== 'REVIEWED'">
								<v-btn
									color="#fff"
									outlined
									:disabled="!(event_procedure.actions_taken.length > 0 || event_procedure.comments.trim() !== '')"
									class="monitoring__event_procedure--action_button unsolved"
									@click="buildEventProcedureData">
									Sin solucionar
								</v-btn>
								<v-btn
									:disabled="!(event_procedure.actions_taken.length > 0 || event_procedure.comments.trim() !== '')"
									color="#31a644"
									class="monitoring__event_procedure--action_button solved"
									@click="event_procedure.solved = true; buildEventProcedureData()">
									Solucionado
								</v-btn>
							</div>
						</div>
					</div>
				</div>

				<div class="monitoring__events_gallery">
					<div class="monitoring__events_gallery--preview_box"
					v-for="(event, e) in events_videos"
					:key="e"
					@click="selectGalleryEvent(event.event_id)">
						<div class="video_container">
							<!-- <event-loader
								v-if="event.show_event_loader || event.video_event_error"
								:eventImage="event.event_image"
								:loadSpeed="event.video_loader_speed"
								:videoError="event.video_event_error"/> -->

							<!-- <video-player
							v-if="event.show_video_player"
							:options="event.options"/> -->
							<image-loader :url="event.event_image" :event_id="event.event_id"/>
						</div>

						<div class="icon">
							<play-icon v-if="event.event_id === selected_event.id" width="30" height="30"></play-icon>
							<check-icon v-else-if="location_reviewed_events.includes(event.event_id)" width="30" height="30"></check-icon>
							<circle-icon v-else width="30" height="30" :color="'#00000094'"></circle-icon>
						</div>

						<div class="event_hour"><span>{{ event.event_time | moment("HH:mm:ss") }}</span></div>
					</div>
				</div>
			</div>
		</div>

		<br>
		<incoming-call-modal
			v-for="(incomingCall, index) in incoming_calls"
			:key="index"
			:show-modal="incomingCall.show"
			:sublocation-id="incomingCall.sublocation_id"
			@show-video-call-modal="answerCall(index)"
			@close-modal="closeModal(index)"
			:sublocation-info="incomingCall.sublocation_info">
		</incoming-call-modal>
		<video-call-modal
			:show-modal="show_video_call_modal"
			:sublocation-id="incoming_call_sublocation_id"
			@close-video-call-modal="restoreModals"
			:incoming-call-on-call="incoming_call_on_call"
			@restore-incoming-call-on-call="restoreIncomingCallOnCall">
		</video-call-modal>
		<sos-alert-modal
			:user_data="current_sos_alert"
			v-if="getSOSAlert()">
		</sos-alert-modal>
		<speaker-section v-if="getShowSpeakers()"></speaker-section>
		<invitations-form v-if="getShowInvitationsForm()"></invitations-form>
		<open-doors v-if="getShowOpenDoors()"></open-doors>
	</article>
</template>

<script>
// import NoticeIcon from "@/components/shared/icons/NoticeIcon.vue";
// import ArrowRightIcon from "@/components/shared/icons/ArrowRightIcon.vue";
import moment from 'moment';
import CircleIcon from "@/components/shared/icons/CircleIcon.vue";
import CircleCheckIcon from "@/components/shared/icons/CircleCheckIcon.vue";
import ArrowCircleRightIcon from "@/components/shared/icons/ArrowCircleRightIcon.vue";
import IncomingCallModal from "./modals/IncomingCallModal.vue";
import VideoCallModal from "./modals/VideoCallModal.vue";
import VideoPlayer from "@/components/shared/VideoPlayer.vue";
import EventLoader from "@/components/shared/EventLoader.vue";
import SOSAlert from "@/components/shared/SOSAlert.vue";
import CheckboxEmpty from "@/components/shared/icons/CheckboxEmpty.vue";
import CheckboxDone from "@/components/shared/icons/CheckboxDone.vue";
import PlayIcon from '@/components/shared/icons/PlayIcon.vue'
import CheckIcon from '@/components/shared/icons/CheckIcon.vue'
export default {
  data(){
		return{
			tabs: null,
			monitored_locations: [],
			location_pending_events: [],
			location_reviewed_events: [],
			location_events_type: null,
			selected_location: null,
			selected_location_name: '',
			selected_company_name: '',
			selected_company_id: null,
			hikcentral_streaming_link: '',
			pending_event_selected: null,
			selected_event: null,
			selected_event_group: null,
			event_index: null,
			event_card_index: null,
			facial_events_ready: false,
			show_event_procedure_form: false,
			show_facial_events_procedure_modal: false,
			img: 'https://images.unsplash.com/photo-1600984575359-310ae7b6bdf2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80',
			total_reviewed_events_pages: 0,
			last_pending_event_record: null,
			current_reviewed_events_page: 1,
			total_pending_events_count: 0,
			loading_events: false,
			show_event_preview: false,
			video_options: {
				preload: 'auto',
				autoplay: true,
				controls: true,
				sources: []
			},
			event_actions_list: [
				{text: "Llamada a Guarda", color: "primary"},
				{text: "Llamar a Policía", color: "alert"},
				// {text: "Llamada a Supervisor", color: "primary"},
				{text: "Llamada al Cliente", color: "primary"},
				{text: "Intrusión", color: "alert"},
				{text: "CCTV Verificado", color: "primary"},
				{text: "Envío Supervisor", color: "alert"},
				{text: "Ronda Guarda", color: "primary"},
				{text: "Falsa alarma", color: "success"},
				{text: "Usuario identificado", color: "primary"},
				{text: "Falsa alarma - Mascota", color: "success"},
				{text: "Falla Energía/Internet", color: "success"},
			],
			show_event_loader: false,
			show_video_player: false,
			fast_speed_event_loader: false,
			event_image: '',
			expanded_event: false,
			event_procedure: {
				actions_taken: [],
				comments: "",
				solved: false,
			},
			search_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			search_date_changed: false,
			show_incoming_call_modal: false,
			show_video_call_modal: false,
			incoming_call_sublocation_id: '',
			on_call: false,
			connection_id: '',
			incoming_call_on_call: false,
			location_info: [],
			incoming_calls: [],
			is_image: false,
			current_sos_alert: '',
			today_active: false,
			pending_active: false,
			new_event_locations: [],
			date: null,
			time: null,
			events_videos: []
		}
	},
	mounted(){
		console.log('Created Component')
		this.subscribeToMonitorChannel()
		this.connection_id = this.getUserId()
		setTimeout(() => this.subscribeVirtualGuardChannel(), 1000)
	},
	channels: {
		VirtualGuardChannel: {
			connected() {
				console.log("Connected to digital guard channel!");
			},
			received(data) {
				if(data){
					const connectionId = data.data.connection_id;
					// Verifica si el mensaje proviene de la misma conexión (emisor)
					if (connectionId !== this.connection_id) {
						console.log('------> data from digital guard', data)
						if(data.data.calling === true){
							this.incoming_call_sublocation_id = data.data.sublocation_id
							this.sublocation_info = data.data.sublocation_info
							var call = {}
							call.show = true
							call.sublocation_id = data.data.sublocation_id
							call.sublocation_info = data.data.sublocation_info
							this.incoming_calls.push(call)
							console.log('------------------>this.incoming_calls', this.incoming_calls)
							this.show_incoming_call_modal = true
							if(this.on_call === true){
								console.log('on calling ---->')
								this.onCall(data.data.sublocation_id)
							}
							this.updateInCallState(true)
						}else if (data.data.notify_call_ended == true) {
							console.log("-----------> notify call ended");
							this.restoreModals();
						}else{
							if(data.data.calling === false && data.data.message === "hangUp"){
								this.updateInCallState(false)
							}
							const matchingIncomingCalls = this.incoming_calls.filter(incomingCall => incomingCall.sublocation_id === data.data.sublocation_id);
							console.log('matchingIncomingCalls--------->', matchingIncomingCalls);
							matchingIncomingCalls.forEach(match => {
								const index = this.incoming_calls.indexOf(match);
								if (index !== -1) {
									this.incoming_calls.splice(index, 1);
								}
							});
						}
					}
				}
			},
			disconnected() {
				console.log("Disconnected to digital guard channel!");
			}
		},
		EventsMonitoringChannel: {
			connected() {
				console.log("Connected to events monitoring channel!");
			},
			received(data) {
				console.log("llega un evento por el canal de monitor!", data);

				if(data.message.event_media) this.updateEventMedia(data.message);
				else if((data.message.suspended_event != true || data.message.suspended_event === undefined) && data.message.event_code != "FUSE-ALERT-SOS"){
					this.addToCurrentEvent(data.message)
					let audio = new Audio(require("@/assets/audio/monitoring_notification.wav"));
					audio.play();
					if (data.message.company_id !== undefined){
						let company_index = this.getMonitoredCompanies().findIndex(e => e.id === data.message.company_id)
						this.getMonitoredCompanies()[company_index].pending_events += 1
						let location_index = this.getMonitoredCompanies()[company_index].assigned_locations.findIndex(e => e.id === data.message.location_id)
						let location = this.getMonitoredCompanies()[company_index].assigned_locations[location_index]
						location_index = this.getPendingLocations().findIndex(e => e.id === location.id)
						if(location_index === -1){
							let loc_object = {
								id: location.id,
								name: location.name,
								event_queue: [],
								pending_events: 1,
								speaker: location.speaker,
								company_id: this.getMonitoredCompanies()[company_index].id
							}
							this.getPendingLocations().unshift(loc_object)
							this.getMonitoredCompanies()[company_index].monitored_locations.unshift(loc_object)
						}else{
							let pending_events_count = this.getPendingLocations()[location_index].pending_events + 1
							this.getPendingLocations().splice(location_index, 1)
							let monitored_loc_index = this.getMonitoredCompanies()[company_index].monitored_locations.findIndex(e => e.id === data.message.location_id)
							this.getMonitoredCompanies()[company_index].monitored_locations.splice(monitored_loc_index, 1)
							let loc_object = {
								id: location.id,
								name: location.name,
								event_queue: [],
								pending_events: pending_events_count,
								speaker: location.speaker,
								company_id: this.getMonitoredCompanies()[company_index].id
							}
							this.getPendingLocations().unshift(loc_object)
							this.getMonitoredCompanies()[company_index].monitored_locations.unshift(loc_object)
						}
					}else{
						if(data.message.event_media) this.updateEventMedia(data.message);
					}

					if(data.message.location_id === this.selected_location){
						let existent_event = this.location_pending_events.filter(e => e.id === data.message.id);
						if(existent_event.length === 0){
							if(["KNOWN FACE"].includes(data.message.event_type)){
								this.location_pending_events.unshift(data.message);
								this.pending_event_selected = this.pending_event_selected == null ? this.location_pending_events[0] : this.pending_event_selected
							} else if(["131585", "131588", "131331", "FUSE-ALERT-SUSPEND", "FUSE-ALERT-ACTIVATE", "FUSE-ALERT-COM", "UNKNOWN FACE"].includes(data.message.event_code)){
								this.location_pending_events.unshift(data.message);
								this.events_videos.unshift({
									options: {
										preload: 'auto',
										autoplay: false,
										controls: false,
										sources: []
									},
									event_image: '',
									event_video: '',
									event_id: data.message.id,
									event_time: data.message.event_time,
									show_video_player: false,
									show_event_loader: true,
									video_event_error: false,
									video_loader_speed: ''
								})
								// this.getLocationEventsFiles(data.message.id)
							}
						}
					}
					console.log('Monitored Company after event added!!!', this.getMonitoredCompanies())
				}
				if(["FUSE-ALERT-SOS"].includes(data.message.event_code)) {
					let audio = new Audio(require("@/assets/audio/monitoring_notification.wav"));
					audio.play();
					console.log("Event Code Excluded !!!!!!!!!! ", data.message.event_code);
					this.current_sos_alert = data.message.text_message
					this.updateSOSAlert(true)
				}
			},
			disconnected() {
				console.log("Disconnected of events mon channel!");
			},
		},
	},
	components: {
		// "notice-icon": NoticeIcon,
		// "arrow-right-icon": ArrowRightIcon,
		"circle-icon" : CircleIcon,
		"circle-check-icon" : CircleCheckIcon,
		"arrow-circle-right-icon" : ArrowCircleRightIcon,
		"incoming-call-modal" : IncomingCallModal,
		"video-call-modal" : VideoCallModal,
		VideoPlayer,
		EventLoader,
		"sos-alert-modal" : SOSAlert,
		"checkbox-empty" : CheckboxEmpty,
		"checkbox-done" : CheckboxDone,
		"play-icon" : PlayIcon,
		"check-icon" : CheckIcon
	},
	watch:{
		$route (){
			this.checkedCompanyEvents(this.getSelectedCompany())
			this.hikcentral_streaming_link = this.getMonitoredCompanies().find(e => e.id === this.getSelectedCompany()).hikcentral_streaming_link
			this.total_reviewed_events_pages = 0
			this.total_pending_events_count = 0
			this.last_pending_event_record = null
			this.current_reviewed_events_page = 1
			this.show_event_preview = false
		},
	},
	methods:{
		removeFromCurrentEvents(location_id){
			var current_events = this.getCurrentEvents()
			var filter_events = current_events.filter(event => event.location == location_id)
			var new_events = current_events.filter(event => !filter_events.includes(event))
			this.updateCurrentEvents(new_events)
		},
		findLocationInEvents(location){
			var vm = this;
			return (vm.getCurrentEvents().filter(event => event.location == location).length > 0)
		},
		addToCurrentEvent(new_event){
			let new_temp_event = { 'company': new_event.company_id, 'location': new_event.location_id }
			let current_events = this.getCurrentEvents()
			current_events.push(new_temp_event)
			this.updateCurrentEvents(current_events)
			console.log("Current Events!!!!!!!!!!!!!!!!!!", this.getCurrentEvents());
		},
		arraymove(company_id, location_id) {
			var arr = this.getMonitoredCompanies()
			console.log("Before ", arr);
			var fromIndex = arr.findIndex(e => e.id === company_id)
			var toIndex = 0
			var element = arr[fromIndex];
			element.new_event_validator = true
			arr.splice(fromIndex, 1);
			arr.splice(toIndex, 0, element);
			console.log("After ", arr);
			this.updateMonitoredCompanies(arr)
			this.arrayLocationsMove(company_id, location_id)
		},
		arrayLocationsMove(company_id, location_id) {
			var company = this.getMonitoredCompanies().find(e => e.id === company_id)
			var arr = company.monitored_locations
			console.log("Before Locations", arr);
			var fromIndex = arr.findIndex(e => e.id === location_id)
			var toIndex = 0
			var element = arr[fromIndex];
			element.new_event_validator = true
			arr.splice(fromIndex, 1);
			arr.splice(toIndex, 0, element);
			console.log("After ", arr);

			// this.updateMonitoredCompanies(company)
		},
		checkedCompanyEvents(company_id) {
			console.log("Checked Company " + company_id);
			var arr = this.getMonitoredCompanies()
			var fromIndex = arr.findIndex(e => e.id === company_id)
			var element = arr[fromIndex];
			element.new_event_validator = false
			element.monitored_locations = element.monitored_locations.filter((location) => location.new_event_validator == true).concat(element.monitored_locations.filter((location) => location.new_event_validator == false).sort((a, b) => a.pending_events - b.pending_events).reverse())
			console.log("Monitored Locations", element.monitored_locations.filter((location) => location.new_event_validator == true));
			this.updateMonitoredCompanies(arr)
		},
		closeModal(index){
			console.log('Index', index)
			this.incoming_calls[index].show = false
			// this.show_incoming_call_modal = false;
			this.incoming_calls.splice(index, 1)
			this.updateInCallState(false)
		},
		selectEvent(event){
			console.log('Event PRELOADED!', event)
			if(
				this.selected_event &&
				this.selected_event.event_state === "PENDING" &&
				this.selected_event.id === event.id
			) return;

			console.log(this.show_event_loader)
			console.log(this.video_event_error)
			console.log(this.event_image)

			this.clearView();

			console.log(this.show_event_loader)
			console.log(this.video_event_error)
			console.log(this.event_image)
			console.log(event);
			this.selected_event = event;
			this.show_event_procedure_form = event.event_state !== 'REVIEWED';
			this.show_event_preview = true;
			this.show_event_loader = true;
			this.location_reviewed_events.push(event.id)
			this.date = moment(event.event_time).format('DD MMM YYYY')
			this.time = moment(event.event_time).format('HH:mm:ss a')
			this.loadEventProcedureData(event);
			this.getEventMediaFiles();
		},
		selectGalleryEvent(event_id){
			let event = this.location_pending_events.find(e => e.id === event_id)
			this.selectEvent(event)
		},
		toggleEventVerification(){
			try {
				if(this.selected_event.event_state === 'REVIEWED') return;

				this.$http.post('company_monitorings/change_event_verification',{
					data: this.encrypt({event_id: this.selected_event.id}).toString()
				}).then(response => {
					this.selected_event.event_state = response.body.current_state;
					console.log("Event and groups!!!!!!!!");
					console.log(this.selected_event, this.location_pending_events, this.pending_event_groups)
					console.log(this.selected_event.group_key);
					console.log(this.pending_event_groups[this.selected_event.group_key]);
					console.log(this.pending_event_groups[this.selected_event.group_key].length);
					console.log(this.pending_event_groups[this.selected_event.group_key].length > 0 );
					if(this.pending_event_groups[this.selected_event.group_key].length > 0 ){
						let current_group = this.pending_event_groups[this.selected_event.group_key]
						console.log("Filter Groups");
						console.log(current_group.filter(event => event.event_state == 'VERIFIED').length == current_group.length);
						if(current_group.filter(event => event.event_state == 'VERIFIED').length == current_group.length ){
							this.selectEvent(this.selected_event, this.selected_event.group_key)
						}
					}
				})
			} catch (e){
				console.error(e)
			}
		},
		toggleActionTaken(action){
			if(this.selected_event.event_state == "REVIEWED") return
			if(this.event_procedure.actions_taken.includes(action)){
				this.event_procedure.actions_taken.splice(this.event_procedure.actions_taken.indexOf(action), 1)
			} else {
				this.event_procedure.actions_taken.push(action)
			}
		},
		loadEventProcedureData(event){
			if(event.event_state === 'REVIEWED'){
				this.event_procedure.actions_taken = event.event_comments.split("|").slice(1);
				this.event_procedure.comments = `${event.event_comments.split("|")[0]}\n\n`;
				this.event_procedure.comments += `Revisión hecha por ${event.reviewed_by_email}\n`;
				this.event_procedure.comments += `${moment(event.review_date).format("DD MMM YYYY - h:mm a")}`;
			} else if(event.event_comments){
				this.event_procedure.actions_taken = event.event_comments.split("|").slice(1);
				this.event_procedure.comments = `${event.event_comments.split("|")[0]}`;
			} else {
				this.event_procedure = {
					actions_taken: [],
					comments: "",
					solved: false,
				}
			}
		},
		buildEventProcedureData(){
			this.submitEventProcedure({
				event_ids: this.location_pending_events.map(ev => ev.id),
				location_id: this.selected_location,
				review_comment: `${this.event_procedure.comments}|${this.event_procedure.actions_taken.join('|')}`,
				event_solved: this.event_procedure.solved,
				user_id: this.getUserId()
			})
		},
		getEventMediaFiles(){
			try {
				this.$http.post('hikcentral/get_event_url',{event_id: this.selected_event.id}).then(function(response){
					this.show_event_loader = true;
					const eventURL = response.body.url;
					console.log("EVENT URL");
					console.log(eventURL);
					if(eventURL.split(".").at(-1) === 'mp4') {
						console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!! URL Video");
						this.setUpEventVideo(eventURL);

					} else if(["jpg", "png", "jpeg"].includes(eventURL.split(".").at(-1))) {
						console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!! URL Image");
						this.downloadEventVideo();
						this.event_image = eventURL;
					}
				})
			} catch (e) {
				console.log(e)
			}
		},
		downloadEventVideo(){
			this.$http.post('hikcentral/event_video', {
				data: this.encrypt({eventId: this.selected_event.id}).toString()
			})
			.catch(() => {
				this.show_event_loader = false;
				this.fast_speed_event_loader = false;
			});
		},
		getLocationEventsFiles(event_id){
			console.log('Get Event Video File of event id: ', event_id)
			try {
				this.$http.post('hikcentral/get_event_url',{event_id: this.selected_event.id}).then(function(response){
					let event_video_index = this.events_videos.findIndex(e => e.event_id === event_id)
					console.log('video index', event_video_index)
					this.events_videos[event_video_index].show_event_loader = true
					const eventURL = response.body.url;

					if(eventURL.split(".").at(-1) === 'mp4') {
						this.events_videos[event_video_index].video_loader_speed = 'fast';
						// this.events_videos[event_video_index].event_image = eventURL;
						this.events_videos[event_video_index].event_video = eventURL;
						this.events_videos[event_video_index].options.sources = [];
						this.events_videos[event_video_index].options.sources.push({src: this.events_videos[event_video_index].event_image, type: 'video/mp4'});
						this.events_videos[event_video_index].show_video_player = true

					} else if(["jpg", "png", "jpeg"].includes(eventURL.split(".").at(-1))) {
						this.downloadEventsVideo(event_id);
						this.events_videos[event_video_index].event_image = eventURL;
					}
				})
			} catch (e) {
				console.log(e)
			}
		},
		downloadEventsVideo(event_id){
			try {
				this.$http.post('hikcentral/event_video', {
					data: this.encrypt({eventId: event_id}).toString()
				})
				.then(() => {
					let event_video_index = this.events_videos.findIndex(e => e.event_id === event_id)
					setTimeout(() => this.events_videos[event_video_index].video_loader_speed = 'slow', 2000);
				})
				.catch(() => {
					let event_video_index = this.events_videos.findIndex(e => e.event_id === event_id)
					this.events_videos[event_video_index].show_event_loader = false;
					this.events_videos[event_video_index].video_event_error = true;
					this.events_videos[event_video_index].video_loader_speed = '';
				});

			} catch (e) {
				console.error(e);
			}
		},
		answerCall(index) {
			console.log('--> answer calls')
			// this.show_incoming_call_modal = false;
			this.incoming_calls[index].show = false
			this.show_video_call_modal = true;
			if(this.on_call == true){
				this.incoming_call_on_call = true
			}
			this.on_call = true
		},
		restoreModals(){
			this.show_video_call_modal = false
			this.on_call = false
			this.incoming_call_on_call = false
			this.updateInCallState(false)
		},
		restoreIncomingCallOnCall(){
			this.incoming_call_on_call = false
		},
		onCall(sublocation_id){
			console.log('notifies you that you are on a call ---->')
			const room = `room_virtual_guard_${sublocation_id}`;
			this.$cable.perform({
			channel: "VirtualGuardChannel",
			action: "send_message",
				data: {
					room: room,
					message: 'onCall',
					on_call: true,
					sublocation_id: sublocation_id,
					connection_id: this.connection_id,
				},
			});
		},
		resize(width, height, left, top) {
			this.$refs.cropper.setCoordinates({
				width: width,
				height: height,
				left: left,
				top: top
			})
		},
		change_cropper({ coordinates, canvas }) {
			console.log(coordinates, canvas)
		},
		getLocationPendingEvents(location_id, max_limit){
			console.log('Search date in pending events:', this.search_date)
			try {
				this.$http.get('company_monitorings/get_location_pending_events',{
					params:{
						user_id: this.getUserId(),
						company_id: this.selected_company_id,
						location_id: location_id,
						last_record_id: this.last_pending_event_record,
						max_limit: max_limit,
						search_date: this.search_date
					},
					headers: {
						"X-Device-ID" : this.getDeviceId(),
						// "Authorization" : 'Bearer ' + this.getUserToken()
					},
				}).then(function(response){
					if(response.body !== ""){
						this.location_events_type = response.body.events_type
						if(this.search_date_changed){
							this.location_pending_events = response.body.pending_events
						} else {
							response.body.pending_events.forEach((element) => this.location_pending_events.push(element));
						}
						console.log('Pending Events',this.location_pending_events)
						this.last_pending_event_record = response.body.last_record_id
						this.total_pending_events_count = response.body.total_pending_events_count
						this.search_date_changed = false
						if(this.location_events_type == "facial"){
							this.total_pending_events_pages = response.body.total_pages
							this.facial_events_ready = true
							if(this.pending_event_selected == null || this.location_pending_events.filter(ev => ev.id == this.pending_event_selected.id)[0] == undefined){
								this.pending_event_selected = this.location_pending_events[0]
							}
						}
						// Auto select last event group and last event of the location
						if (this.selected_event === null && this.location_pending_events.length > 0){
							var auto_selected_event = this.location_pending_events[0]
							this.selectEvent(auto_selected_event)
						}
						this.location_pending_events.forEach(event => {
							this.events_videos.push({
								options: {
									preload: 'auto',
									autoplay: false,
									controls: false,
									sources: []
								},
								event_image: event.event_image,
								event_video: event.event_video,
								event_id: event.id,
								event_time: event.event_time,
								show_video_player: false,
								show_event_loader: true,
								video_event_error: false,
								video_loader_speed: ''
							})

							this.getLocationEventsFiles(event.id)
						});
					}else{
						this.location_pending_events = []
					}
					// this.pages = response.body.meta.pages
					if(response.body.meta != undefined && response.body.meta != null){
						if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
							this.checkToken(response.body.meta.authentication_token)
						}
					}
				}, function(response){
					if(response.body.meta != undefined && response.body.meta != null){
						if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
							this.checkToken(response.body.meta.authentication_token)
						}
					}
					console.log(response);
				})
			} catch (e) {
				alert(e.message)
			}
		},
		subscribeToMonitorChannel() {
			this.unsubscribeFromMonitorChannel()
			console.log('Entra a suscribirse al canal del Monitor!!!', this.getUserId())
			// Suscribirse al canal del monitor
			this.$cable.subscribe({
				channel: "EventsMonitoringChannel",
				room: `room_events_monitoring_${this.getUserId()}`,
				monitor_id: this.getUserId(),
			});
		},
		unsubscribeFromMonitorChannel() {
			this.$cable.unsubscribe("EventsMonitoringChannel")
		},
		subscribeVirtualGuardChannel(){
			this.unsubscribeVirtualGuardChannel()
			console.log('subscribeVirtualGuardChannel')
			var companies = this.getMonitoredCompanies()
			console.log('Companies in subscribe method', companies)
			companies.forEach(company => {
				console.log('-----------> company', company)
				if(company.monitoring_calls){
					company.assigned_locations.forEach(location => {
						location.sublocations.forEach(sublocation => {
							if(sublocation.enable_calls){
								console.log('Se suscribe al canal para la sublocación', sublocation.id)
								this.$cable.subscribe({
									channel: "VirtualGuardChannel",
									room: `room_virtual_guard_${sublocation.id}`,
									sublocation_id: sublocation.id,
								});
							}
						});
					});
				}
			});
		},
		unsubscribeVirtualGuardChannel() {
			this.$cable.unsubscribe("VirtualGuardChannel")
		},
		selectLocation(location_id, company_id){
			if(location_id !== this.selected_location){
				this.clearEventsData()
				this.selected_location = location_id
				let company_index = this.getMonitoredCompanies().findIndex(e => e.id === company_id)
				let location_index = this.getPendingLocations().findIndex(e => e.id === location_id)
				this.selected_company_name = this.getMonitoredCompanies()[company_index].name
				this.selected_location_name = this.getPendingLocations()[location_index].name
				this.selected_company_id = company_id
				console.log(this.selected_company_id)
				this.today_active = true
				this.pending_active = false
				this.search_date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
				this.getLocationPendingEvents(location_id, true)
			}
		},
		checkCameras(){
			// let default_url = "https://181.48.103.61/"
			// window.open(default_url, "_blank");
			window.open(this.hikcentral_streaming_link, "_blank");
		},
		resizeFacialEventPhoto(imgURL, imgParams){
			console.log(imgURL, imgParams);
		// 	console.log("<<<<<<<<<<<<<<<<<<<<< Img Params");
		// 	console.log(imgParams);
		// 	const info = { url: imgURL, crossOrigin: "*" }
		// 	try {
		// 		let $canvas = document.createElement('canvas')
		// 		let img = new Image()
		// 		img.src = imgURL
		// 		console.log("<<<<<<<<<<<<<<<<< Image");
		// 		console.log($canvas)
		// 		console.log(img);
		// 		img.crossOrigin = info.crossOrigin;
		// 		img.onload = () => {
		// 			$canvas.width = imgParams.width * img.width
		// 			$canvas.height = imgParams.height * img.height
		// 			$canvas.getContext('2d').drawImage(img, imgParams.x * img.width, imgParams.y * img.height, imgParams.width * img.width, imgParams.height * img.height, 0, 0, imgParams.width * img.width, imgParams.height * img.height)
		// 			return $canvas.toDataURL("image/jpeg")
		// 		};
		// 	} catch (e){
		// 		console.error("Algo ocurrió con el recorte de la imagen")
		// 		// res("https://cdn-icons-png.flaticon.com/512/456/456212.png")
		// 	}
		},
		submitEventProcedure(payload){
			this.show_facial_events_procedure_modal = false
			try {
				this.$http.post('company_monitorings/update_reviewed_event',{
					data: this.encrypt(payload).toString()
				})
				.then(response => {
					if(response.status == 200){
						this.show_event_preview = false
						if(payload.event_solved){
							this.location_pending_events = []
							this.events_videos = []
							this.date = null
							this.time = null
						}
						console.log('Pending locations', this.getPendingLocations())
						console.log('selected location index', this.selected_location)
						console.log('location index on payload', payload.location_id)
						let location_index = this.getPendingLocations().findIndex(e => e.id === this.selected_location)
						console.log('location index of reviewed:', location_index)
						this.getPendingLocations().splice(location_index, 1)
						console.log('Pending locations after delete:', this.getPendingLocations())
						let comp_index = this.getMonitoredCompanies().findIndex(e => e.id === this.selected_company_id)
						console.log('company index!', comp_index)
						let monitored_loc_index = this.getMonitoredCompanies()[comp_index].monitored_locations.findIndex(e => e.id === this.selected_location)
						console.log('monitored loc index!', monitored_loc_index)
						this.getMonitoredCompanies()[comp_index].monitored_locations.splice(monitored_loc_index, 1)
						console.log('Monitored locations of company', this.getMonitoredCompanies()[comp_index].monitored_locations)
						this.clearEventsData()
					} else {
						console.error(response.body.ans)
					}
				})
			} catch (e) {
				console.log(e)
			}
		},
		clearView(){
			this.show_event_loader = false;
			this.video_event_error = false;
			this.video_loader_speed = '';
			this.fast_speed_event_loader = false;
			this.event_image = '';
			this.show_video_player = false;
		},
		clearEventsData(){
			this.location_pending_events = []
			this.total_reviewed_events_pages = 0
			this.total_pending_events_count = 0
			this.last_pending_event_record = null
			this.current_reviewed_events_page = 1
			this.show_event_preview = false
			this.selected_event = null
			this.selected_event_group = null
			this.tabs = null
			this.search_date = ''
			this.search_date_changed = false
			this.events_videos = []
			this.selected_company_id = null
			this.selected_company_name = ''
			this.selected_location = null
			this.selected_location_name = ''
		},
		updateEventMedia(data){
			if(data.event_id === this.selected_event.id) {
				const eventMediaUrl = data.event_media;
				const eventMediaExtension = eventMediaUrl.split(".").at(-1);

				if(eventMediaExtension === 'mp4') this.setUpEventVideo(eventMediaUrl);
				else if(["jpg", "png", "jpeg"].includes(eventMediaExtension)){
					this.downloadEventVideo();
					this.event_image = eventMediaUrl;
				}
			}
		},
		setUpEventVideo(videoUrl){
			this.fast_speed_event_loader = true;
			this.selected_event.event_image = videoUrl;
			this.video_options.sources = [];
			this.video_options.sources.push({src: this.selected_event.event_image, type: 'video/mp4'});
			this.show_video_player = true
			setTimeout(() => {
				this.show_event_loader = false;
				this.event_image = '';
			}, 600);
		},
		updateCurrentTime() {
			var day = moment().format("DD ")
			var month = moment().format("MMMM ").toString()
			var capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
			var year = moment().format("YYYY ")
			this.date = day + capitalizedMonth + year
			this.time = moment().format("h:mm a");
		},
	}
}
</script>

<style scoped>
.cropper {
	height: 85px;
	width: 85px;
	background: #DDD;
}
</style>
