<template>
  <v-dialog
    v-model="showModal"
    :width="width"
    persistent
    hide-overlay
    no-click-animation
  >
    <v-card class="video_call" :style="{ height: height }">
      <v-card-title class="text-right">
        <div class="video_call__navbar_buttons">
          <img
            class="video_call__navbar_buttons--maximize"
            src="@/assets/img/maximize.png"
            alt="Maximizar"
            @click="maximizeDialog"
          />
          <img
            class="video_call__navbar_buttons--minimize"
            src="@/assets/img/minimize.png"
            alt="Minimizar"
            @click="minimizeDialog"
          />
        </div>
      </v-card-title>

      <div id="meet" :style="{ height: height_meet }"></div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      audio: null,
      api: null,
      previousSublocationId: null,
      width: "40%",
      height: "85vh",
      height_meet: "92%",
    };
  },
  props: ["showModal", "sublocationId", "incomingCallOnCall"],

  mounted() {
    this.makeDialogsDraggable();
  },

  methods: {
    makeDialogsDraggable() {
      const d = {};
      document.addEventListener("mousedown", (e) => {
        const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
        if (
          e.button === 0 &&
          closestDialog != null &&
          e.target.classList.contains("v-card__title")
        ) {
          d.el = closestDialog;
          d.mouseStartX = e.clientX;
          d.mouseStartY = e.clientY;
          d.elStartX = d.el.getBoundingClientRect().left;
          d.elStartY = d.el.getBoundingClientRect().top;
          d.el.style.position = "fixed";
          d.el.style.margin = 0;
          d.oldTransition = d.el.style.transition;
          d.el.style.transition = "none";
        }
      });
      document.addEventListener("mousemove", (e) => {
        if (d.el === undefined) return;
        d.el.style.left =
          Math.min(
            Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
            window.innerWidth - d.el.getBoundingClientRect().width
          ) + "px";
        d.el.style.top =
          Math.min(
            Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
            window.innerHeight - d.el.getBoundingClientRect().height
          ) + "px";
      });
      document.addEventListener("mouseup", () => {
        if (d.el === undefined) return;
        d.el.style.transition = d.oldTransition;
        d.el = undefined;
      });
      setInterval(() => {
        const dialog = document.querySelector(".v-dialog.v-dialog--active");
        if (dialog === null) return;
        dialog.style.left =
          Math.min(
            parseInt(dialog.style.left),
            window.innerWidth - dialog.getBoundingClientRect().width
          ) + "px";
        dialog.style.top =
          Math.min(
            parseInt(dialog.style.top),
            window.innerHeight - dialog.getBoundingClientRect().height
          ) + "px";
      }, 100);
    },

    maximizeDialog() {
      this.width = "40%";
      this.height = "85vh";
      this.height_meet = "92%";
    },

    minimizeDialog() {
      this.width = "20%";
      this.height = "40vh";
      this.height_meet = "85%";
    },

    notifyCallEnded() {
      this.$emit("restore-incoming-call-on-call");
      const room = `room_virtual_guard_${this.previousSublocationId}`;
      this.$cable.perform({
        channel: "VirtualGuardChannel",
        action: "send_message",
        data: {
          room: room,
          message: "notifyCallEnded",
          notify_call_ended: true,
          sublocation_id: this.previousSublocationId,
          connection_id: this.getUserId(),
        },
      });
    },

    getToken() {
      try {
        this.$http
          .get("digital_guard/generate_jwt", {
            params: {
              sublocation_id: this.sublocationId,
            },
            headers: {
              "X-Device-ID": this.getDeviceId(),
              Authorization: process.env.VUE_APP_JITSI_TOKEN_AUTHORIZATION,
            },
          })
          .then(function(response) {
            console.log("-------->RESPONSE TOKEN", response);
            var token = response.body.data;
            this.launchVideoCall(token);
          });
      } catch (e) {
        alert(e.message);
      }
    },

    launchVideoCall(token) {
      console.log("--------> launchVideoCall");
      const domain = process.env.VUE_APP_JITSI_URL;
      const options = {
        roomName: `room_virtual_guard_${this.sublocationId}`,
        width: "100%",
        height: "100%",
        lang: "es",
        parentNode: document.querySelector("#meet"),
        userInfo: {
          email: this.getEmail(),
          displayName: "Monitor",
          role: "moderator",
        },
        configOverwrite: {
          prejoinPageEnabled: false,
          disableDeepLinking: true,
        },
        interfaceConfigOverwrite: {
          TOOLBAR_ALWAYS_VISIBLE: true,
        },
        jwt: token,
      };

      this.api = new window.JitsiMeetExternalAPI(domain, options);

      this.api.addListener("readyToClose", () => {
        console.log("---------------> readyToClose");
        this.$emit("close-video-call-modal");
        const room = `room_virtual_guard_${this.sublocationId}`;
        // this.notifyCallEnded();
        this.$cable.perform({
          channel: "VirtualGuardChannel",
          action: "send_message",
          data: {
            room: room,
            message: "notifyCallEnded",
            notify_call_ended: true,
            sublocation_id: this.sublocationId,
            connection_id: this.getUserId(),
          },
        });
        this.api.dispose();
        this.showModal = false;
      });
    },
  },

  watch: {
    sublocationId(newSublocationId, oldSublocationId) {
      // console.log('-----------############### oldLocationId', oldLocationId)
      console.log("sublocationId changed:", newSublocationId);
      this.previousSublocationId = oldSublocationId;
    },

    showModal(newVal) {
      console.log("showModal changed:", newVal);

      if (newVal) {
        this.getToken();
      } else {
        this.api.dispose();
        console.log("closed");
      }
    },

    incomingCallOnCall(newVal) {
      console.log("incomingCallOnCall changed:", newVal);

      if (newVal) {
        console.log("--------> close previous call");
        this.notifyCallEnded();
        this.api.dispose();
        this.getToken();
      }
    },
  },
};
</script>

<style scoped></style>
