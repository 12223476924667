// import Vue from 'vue'
import Vue from 'vue'
// Menu
import Navigation from '@/components/shared/Navigation.vue'

// Icons
import Logo from '@/components/shared/Logo.vue'
import CompanyLogo from '@/components/shared/CompanyLogo.vue'

// Loader
import Loader from '@/components/shared/Loader.vue'


import Login from '@/components/shared/Login.vue'
import SecurityCameraIcon from '@/components/shared/icons/SecurityCameraIcon.vue'
import ClientsIcon from '@/components/shared/icons/ClientsIcon.vue'
import CallSectionIcon from '@/components/shared/icons/CallSectionIcon.vue'
import InvitationSectionIcon from '@/components/shared/icons/InvitationSectionIcon.vue'
import OpenDoorSectionIcon from '@/components/shared/icons/OpenDoorSectionIcon.vue'
import SpeakerSectionIcon from '@/components/shared/icons/SpeakerSectionIcon.vue'
import DropDownArrow from '@/components/shared/icons/DropDownArrow.vue'
import CompanySections from '@/components/shared/CompanySections.vue'
import SpeakerSection from '@/components/speakers/Index.vue'
import InvitationsForm from '@/components/invitations/InvitationsForm.vue'
import ArrowDoor from '@/components/shared/icons/ArrowDoor.vue'
import OpenDoors from '@/components/open_doors/OpenDoors.vue'
import OpenDoorIcon from '@/components/shared/icons/OpenDoorIcon.vue'
import MicrophoneIcon from '@/components/shared/icons/MicrophoneIcon.vue'
import CancelAudioIcon from '@/components/shared/icons/CancelAudioIcon.vue'
import SendAudioIcon from '@/components/shared/icons/SendAudioIcon.vue'
import EventDot from '@/components/shared/icons/EventDot.vue'
import GuardShield from '@/components/shared/icons/GuardShield.vue'
import VueVideoThumbnail from 'vue-video-thumbnail'
import ImageLoader from '@/components/shared/ImageLoader.vue'
import CommentEventModal from '@/components/modals/NewCommentEventModal.vue'
import AlertModal from '@/components/shared/AlertModal.vue'

Vue.component('navigation', Navigation);
Vue.component('app-logo', Logo);
Vue.component('company-logo', CompanyLogo);
Vue.component('login', Login);
Vue.component('loader', Loader);
Vue.component('security-camera-icon', SecurityCameraIcon);
Vue.component('clients-icon', ClientsIcon);
Vue.component('call-section-icon', CallSectionIcon);
Vue.component('invitation-section-icon', InvitationSectionIcon);
Vue.component('open-door-section-icon', OpenDoorSectionIcon);
Vue.component('speaker-section-icon', SpeakerSectionIcon);
Vue.component('drop-down-arrow', DropDownArrow);
Vue.component('company-sections', CompanySections);
Vue.component('speaker-section', SpeakerSection);
Vue.component('invitations-form', InvitationsForm);
Vue.component('arrow-door', ArrowDoor);
Vue.component('open-doors', OpenDoors);
Vue.component('open-door-icon', OpenDoorIcon);
Vue.component('microphone-icon', MicrophoneIcon);
Vue.component('cancel-audio-icon', CancelAudioIcon);
Vue.component('send-audio-icon', SendAudioIcon);
Vue.component('event-dot', EventDot);
Vue.component('guard-shield', GuardShield);
Vue.component('video-thumbnail', VueVideoThumbnail);
Vue.component('image-loader', ImageLoader);
Vue.component('comment-event-modal', CommentEventModal);
Vue.component('alert-modal', AlertModal);
