<template>
  <article class="speakers">
    <section class="speakers__container" style="min-height: inherit">
      <article id="alarms" class="news">
        <div class="invitation__new--title">
          <p class="invitation__new_text--title">Puertas Habilitadas</p>
        </div>
        <div class="alarms__location_div">
          <div
            :class="{ alarms__content_div: true, }"
            v-for="(location, index) in locations"
            :key="location.location">
            <div
              class="alarms__title"
              @click="selectLocation(index)">
              <div
                class="alarms__title--image"
                :style="{backgroundImage: 'linear-gradient(black, black), url('+location.image+')'}">
              </div>
              <p class="invitation__new_text--title">{{location.location}}</p>
            </div>
            <Transition name="fade">
              <div v-if="selected_location == index">
                <section
                  class="alarms__slide_content"
                  :key="door.id"
                  v-for="door in location.doors">
                  <div class="alarms__door_container">
                    <p class="alarms__door_container--title">{{door.name}}</p>
                    <!-- <div
                      :id="door.id"
                      class="movil-box alarms__door"
                      :ref="'movilBox'+door.id"
                      @click="manejarTransicion(door.id)">
                      <open-door-icon></open-door-icon>
                    </div> -->
                    <div
                      :id="door.id"
                      class="movil-box alarms__door"
                      :ref="'movilBox'+door.id">
                      <open-door-icon></open-door-icon>
                    </div>
                  </div>
                </section>
              </div>
            </Transition>
          </div>
        </div>
        <div class="invitation__new--actions">
          <p
            class="invitation__buttons--gray"
            @click="updateShowOpenDoors(false)">CANCELAR</p>
        </div>
      </article>
    </section>
  </article>
</template>

<script>
	export default {
		data() {
			return {
				show_modal_success: false,
        show_modal_error: false,
        titleModal: "",
        textModal: "",
        locations: [],
        awaitTimeout: 1000 * 5,
        puntoEspecifico: 100, // Punto específico en el que gatillará la función
        posicionActual: 0,
        toqueIniciado: false,
        selected_location: null,
        return_home: false,
        temp_door: null,
        show_validator_modal: false
      };
		},
		channels: {
			IntegratorPetitionChannel: {
				connected() {},
				rejected() {},
				received(data) {
					let response = data.response;
					this.locations.map((loc) => {
						loc.doors.map((door) => {
							if (door.id == response.door) {
								clearTimeout(door.timmer);
								door.state = "Free";
								this.updateLoader(false)
								if (response.code != "0") {
									this.textModal = response.code == "2" ? response.msg : "Dispositivo desconectado, por favor revisa la conexion de los equipos"
									this.show_modal_error = true;
									// this.show_modal_error = true;
								}else {
									this.titleModal = "Puerta Abierta";
									this.textModal =
										"Puerta Abierta exitosamente";
									this.show_modal_success = true;
								}
							}
						});
					});
				},
				disconnected() {},
			},
		},
		methods: {
			closeModal(){
				this.show_validator_modal = false
			},
			checkPin(value){
				console.log("PIN Value ---------- ", value);
				this.closeModal();
				this.open_door(this.temp_door, value);
			},
			selectLocation(index){
				console.log(this.selected_location);
				this.selected_location = index
			},
			manejarInicioToque(evento) {
        this.toqueIniciado = true;
        console.log(evento);
      },
      manejarMovimientoToque(evento) {
        const parent = evento.currentTarget.parentNode;
        let maxPosition = parent.getBoundingClientRect().width - evento.currentTarget.getBoundingClientRect().width
        if (this.toqueIniciado) {
          const x = evento.touches[0].clientX;
          evento.currentTarget.style.transform = (x <= maxPosition ? `translateX(${x}px)` : `translateX(${maxPosition}px)`);
        }
      },
      manejarFinToque(evento){
        this.toqueIniciado = false;
        const parent = evento.currentTarget.parentNode;
        const x = evento.changedTouches[0].clientX;
        let maxPosition = parent.getBoundingClientRect().width - evento.currentTarget.getBoundingClientRect().width
        console.log("Touched Element ", evento.currentTarget.id);
        console.log("Touched Element ", evento);
        console.log(x);
        console.log(maxPosition);
        if(x >= maxPosition){
          console.log("Supero");
          this.manejarTransicion(evento.currentTarget.id);
        }
        evento.currentTarget.style.transform = "translateX(0)";
      },
      manejarTransicion(id){
        console.log("Cuadro llegó al punto específico. ¡Función gatillada!");
        console.log(id);
        let event_door = this.locations[this.selected_location].doors.filter((door) => door.id == id)[0]
        console.log(event_door);
        this.temp_door = event_door
        console.log("Temp Door!!!!!!-------");
        console.log(this.temp_door);
        this.show_validator_modal = true
        this.open_door(this.temp_door, '');
      },
      open_door(door, pin) {
				console.log("Open Door");
				console.log("Object ", door);
				if (door.state != "Pending") {
					var vm = this;
					vm.updateLoader(true)
					console.log("Door Pending");
					door.state = "Pending";
					this.$cable.perform({
						channel: "IntegratorPetitionChannel",
						action: "petition",
						data: {
							user_id: this.getUserId(),
							petition_action: "open_door",
							door: door.id,
							user_pin: pin
						},
					});
					door.timmer = setTimeout(() => {
						vm.textModal = "Dispositivo desconectado, por favor revisa la conexion de los equipos";
						vm.show_modal_error = true;
						console.log(
							"Dispositivo desconectado, por favor revisa la conexion de los equipos"
						);
						door.state = "Free";
						vm.temp_door = null
						vm.updateLoader(false)
					}, this.awaitTimeout);
				}
				console.log("End of Petition");
				// this.updateLoader(false)
        if (this.textModal != "") {
          alert(this.textModal)
        } else{
          this.updateShowOpenDoors(false)
          alert("Apertura de puerta exitosa")
        }
				// return door;
			},
		},
		mounted() {
			let user_id = this.getUserId();
			this.$cable.subscribe({
				channel: "IntegratorPetitionChannel",
				room: "integrator_petition_us_" + user_id,
				user_id,
			});
			this.$http
				.get("app/locations/monitor_locations_doors_to_open/"+this.getSelectedCompany(), {
					headers: {
						"X-Device-ID": this.buildDeviceId(),
						Authorization: "Bearer " + this.getUserToken()
					},
				})
				.then(
					function (response) {
						let res = response.body;
						this.locations = res.locations;
					},
					function (response) {
						if (response.status == 401 && response.status == 500) {
							console.log("Error ", response);
							this.show_modal_error = true;
							this.return_home = true;
							this.textModal =
								"No tienes acceso a este módulo. Comunícate con el administrador de la plataforma";
						}
					}
				);
		},
		beforeUnmount() {
			this.$cable.connection.disconnect();
		},
	};
</script>
<style scoped>
	/* Estilos adicionales si es necesario */
  .movil-box {
    width: 50px;
    height: 50px;
    background-image: linear-gradient(to bottom, #64B5F6 0%, #0076FF 80%, #0D47A1 100%);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease; /* Ajusta la duración y el efecto de transición según tus necesidades */
  }
	.alarms__location_div{
		padding-bottom: 15%;
	}
	.alarms__content_div{
		border: 1px solid #9E9E9E;
    border-radius: 10px;
		box-shadow: 0px 0px 15px 0px #cfd8dc;
    margin-bottom: 25px !important;
	}
</style>
