<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 799 799">
    <path
      id="Path_20"
      data-name="Path 20"
      d="M0,399.5C0,178.86,178.86,0,399.5,0s399.5,178.86,399.5,399.5-178.86,399.5-399.5,399.5S0,620.14,0,399.5Z"
      style="fill: #D1C4E9; stroke-width: 0px;"/>
    <path
      d="M545.4,149H254.6c-6.42,0-11.63,5.21-11.63,11.63v395.49c0,6.42,5.21,11.63,11.63,11.63s11.63-5.21,11.63-11.63V172.26h267.54v8.36l-122.14,88.67v379.71l122.8-89.15c1.57,4.58,5.86,7.9,10.97,7.9,6.42,0,11.63-5.21,11.63-11.63V160.63c0-6.42-5.21-11.63-11.63-11.63ZM457.77,468c-5.48,0-10.05-6.77-11.23-15.84h-13.1v-10.18h13.28c1.39-8.51,5.78-14.78,11.05-14.78,6.37,0,11.54,9.13,11.54,20.4,0,11.26-5.16,20.4-11.54,20.4Z"
      style="fill: #343434; stroke-width: 0px;"/>
  </svg>
</template>

<script>
export default {
  props:{
    color: {
      type: String,
      default: "#F57C00"
    }
  }
};
</script>

<style lang="css">
</style>
