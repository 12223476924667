<template>
  <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg" data-name="Capa 1">
   <g>
    <title>Layer 1</title>
    <g stroke="null" id="svg_4">
     <path stroke="null" id="Path_71" data-name="Path 71" d="m0,24.92998c0,-13.76851 11.16148,-24.92998 24.92998,-24.92998s24.92998,11.16148 24.92998,24.92998s-11.16148,24.92998 -24.92998,24.92998s-24.92998,-11.1621 -24.92998,-24.92998l0,0z" fill=" #C8E6C9" stroke-width=" 0px"/>
     <g stroke="null" id="svg_1">
      <g stroke="null" id="svg_2">
       <path stroke="null" d="m38.65948,19.15913l-1.55323,0c-0.45244,-2.62775 -1.74565,-5.03474 -3.71899,-6.87895c-2.29664,-2.14635 -5.30031,-3.32841 -8.45783,-3.32841c-3.15771,0 -6.16138,1.18206 -8.4579,3.32841c-1.97328,1.84427 -3.26624,4.25114 -3.71875,6.87889l-1.55342,0c-0.75827,0 -1.37298,0.61471 -1.37298,1.37298l0,10.98404c0,0.75827 0.61471,1.37298 1.37298,1.37298l5.49199,0c0.75827,0 1.37298,-0.61471 1.37298,-1.37298l0,-10.98404c0,-0.75827 -0.61471,-1.37298 -1.37298,-1.37298l-1.13893,0c0.96816,-4.28453 4.83421,-7.46127 9.377,-7.46127c4.54273,0 8.40871,3.17674 9.37706,7.46133l-1.13905,0c-0.75827,0 -1.37298,0.61471 -1.37248,1.37298l0,10.98404c0,0.75827 0.61471,1.37298 1.37298,1.37298l1.37298,0l0,3.11002c0,0.68768 -0.55948,1.24715 -1.24715,1.24715l-2.36817,0c-0.18868,-0.53289 -0.69567,-0.91536 -1.29322,-0.91536l-4.70245,0c-0.75827,0 -1.37298,0.61471 -1.37298,1.37298l0,1.83066c0,0.75827 0.61471,1.37298 1.37298,1.37298l4.70251,0c0.59755,0 1.10441,-0.38241 1.29322,-0.91536l2.36817,0c2.20184,0 3.99318,-1.79134 3.99318,-3.99318l0,-3.11002l1.37298,0c0.75827,0 1.37298,-0.61471 1.37298,-1.37298l0,-10.98385c-0.0005,-0.75839 -0.61521,-1.37304 -1.37348,-1.37304zm-23.34118,10.98404l-2.7459,0l0,-8.23801l1.39058,0c0.00268,0 0.00537,0.00025 0.00805,0.00025c0.00193,0 0.00368,-0.00025 0.00549,-0.00025l1.34177,0l0,8.23801zm21.96813,0l-2.74602,0l0,-8.23801l2.74602,0l0,8.23801z" fill=" #343434" stroke-width=" 0px" id="svg_3"/>
      </g>
     </g>
    </g>
   </g>
  </svg>
</template>

<script>
export default {
  props:{
    color: {
      type: String,
      default: "#03A9F4"
    }
  }
};
</script>

<style lang="css">
</style>
