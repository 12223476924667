<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 799 799">
    <path
      id="Path_20"
      data-name="Path 20"
      d="M0,399.5C0,178.86,178.86,0,399.5,0s399.5,178.86,399.5,399.5-178.86,399.5-399.5,399.5S0,620.14,0,399.5Z"
      style="fill: #FFE0B2; stroke-width: 0px;"/>
    <g>
      <path
      d="M633.88,210.98h-19.32l3.14-11.52c1.28-4.68.3-9.72-2.66-13.6-2.96-3.86-7.55-6.13-12.42-6.13H165.13c-8.63,0-15.63,6.99-15.63,15.63v171.88c0,8.64,7,15.63,15.63,15.63h117.2l42.18,56.24c2.95,3.94,7.58,6.26,12.5,6.26h15.63v31.25c0,25.86-21.04,46.91-46.91,46.91h-78.09v-15.63c0-5.22-2.61-10.1-6.96-13l-46.88-31.25c-4.81-3.2-10.99-3.51-16.04-.78-5.08,2.72-8.25,8.01-8.25,13.78v125c0,5.77,3.17,11.06,8.25,13.78,2.3,1.24,4.84,1.85,7.37,1.85,3.03,0,6.05-.88,8.67-2.62l46.88-31.25c4.35-2.9,6.96-7.78,6.96-13v-15.63h78.09c43.1,0,78.16-35.06,78.16-78.16v-31.25h15.63c4.92,0,9.55-2.32,12.5-6.26l42.18-56.24h101.57c7.05,0,13.22-4.71,15.08-11.52l9.64-35.35h53.41c8.63,0,15.63-6.99,15.63-15.63v-93.75c0-8.64-7-15.63-15.63-15.63h0ZM196.38,562.03l-15.63,10.42v-66.62l15.63,10.42v45.78ZM391.69,414.11h-46.88l-23.44-31.25h93.75l-23.44,31.25ZM543.82,351.61H180.75v-140.63h401.42l-38.35,140.63ZM618.25,304.73h-29.26l17.05-62.5h12.21v62.5h0Z"
      style="fill: #343434; stroke-width: 0px;"/>
      <circle
        cx="227.63"
        cy="257.86"
        r="15.63"
        style="fill: #343434; stroke-width: 0px;"/>
      <circle
        cx="290.13"
        cy="257.86"
        r="15.63"
        style="fill: #343434; stroke-width: 0px;"/>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
