<template>
  <article class="speakers">
    <section class="speakers__container">
      <div
        class="speakers__close sos_alert__close"
        style="z-index: 10"
        @click="updateShowInvitationsForm(false)">
      </div>
      <div class="invitation__new">
        <div class="invitation__new--title">
          <p class="invitation__new_text--title">Crear Invitación</p>
        </div>
        <div class="invitation__new--info_container">
          <v-flex xs12>
            <v-text-field
              prepend-icon="add"
              label="Indicativo país"
              type="text"
              @keypress="isNumber($event)"
              v-validate="'required|min_value:1'"
              maxlength="3"
              v-model="new_invitation.country_phone_code"
              name="country_phone_code"></v-text-field>
            <p
              v-show="errors.has('country_phone_code')"
              class="invitation__new--error_message">Ingresa un indicativo de país válido</p>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              label="Número de celular"
              type="text"
              @keypress="isNumber($event)"
              v-validate="'required|numeric|min:10'"
              maxlength="10"
              v-model="new_invitation.phone"
              name="phone"></v-text-field>
            <p
              v-show="errors.has('phone')"
              class="invitation__new--error_message">Ingresa un número de celular válido</p>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              label="Nombre"
              v-model="new_invitation.name"
              v-validate="'required|alpha_spaces'"
              name="name"></v-text-field>
            <p
              v-show="errors.has('name')"
              class="invitation__new--error_message">Ingresa un nombre válido</p>
          </v-flex>
          <v-flex xs12 sm12 >
            <p class="invitation__new_text--small_gray">Locación</p>
            <v-select
              :items="locations"
              item-text="name"
              item-value="id"
              v-model="new_invitation.location_id"
              single-line
              label="Selecciona una locación"
              name="location"
              v-validate="'required'"></v-select>
            <p
              v-show="errors.has('location')"
              class="invitation__new--error_message">Debes seleccionar una locación</p>
          </v-flex>
          <v-flex xs12 sm12>
            <label class="invitation__new_text--small_gray">Fecha y hora de ingreso</label>
            <date-picker
              v-model="new_invitation.valid_from"
              type="datetime"
              class="invitation__new--datetime_input"
              v-validate="'required'"
              :confirm="true"
              placeholder="Selecciona una fecha de ingreso"
              confirm-text="Seleccionar"
              format="YYYY/MM/DD hh:mm a"
              name="valid_from"></date-picker>
            <p
              v-show="errors.has('valid_from')"
              class="invitation__new--error_message">Debes seleccionar una fecha de ingreso</p>
          </v-flex>
          <v-flex xs12 sm12>
            <label class="invitation__new_text--small_gray">Fecha y hora de salida</label>
            <date-picker
              v-model="new_invitation.valid_until"
              type="datetime"
              class="invitation__new--datetime_input"
              v-validate="'required'"
              :confirm="true"
              placeholder="Selecciona una fecha de salida"
              confirm-text="Seleccionar"
              format="YYYY/MM/DD hh:mm a"
              name="valid_until"></date-picker>
            <p
              v-show="errors.has('valid_until')"
              class="invitation__new--error_message">Debes seleccionar una fecha de salida</p>
          </v-flex>

          <div class="invitation__new--actions">
            <p
              class="invitation__buttons--blue"
              @click="createInvitation();">ENVIAR</p>
            <p
              class="invitation__buttons--gray"
              @click="datePickerFirstClick2=false; clearNewInvitationData()">CANCELAR</p>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  data(){
    return{
      show_init_time_picker: false,
      show_finish_time_picker: false,
      saved_default_option:false,
      pagination_query:false,
      date_available:false,
      invitations_size:0,
      modal_create: false,
      edit_photo_modal: false,
      modal_cancel: false,
      date: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),
      select: [],
      menu: false,
      menu2:false,
      menunew: false,
      menu2new:false,
      datePickerFirstClick:false,
      datePickerFirstClick2:false,
      pages:2,
      pagination: {page: 1, rowsPerPage: 10},
      invitations: [],
      loaded: false,
      newemail:'',
      newlocation: '',
      locations: [],
      headers: [
        {text:'Nombre', value: 'name', sortable: false},
        {text:'# Celular', value: 'mobile', sortable: false},
        {text:'Locación', value: 'location', sortable: false},
        {text:'Fecha Desde', value: 'date_from', sortable: false},
        {text:'Fecha Hasta', value: 'date_until', sortable: false},
        {text:'Foto', value: 'photo', sortable: false},
        {text:'Estado', value: 'invitation_state', sortable: false},
        {text: 'Acción', value: 'action', sortable: false}
      ],
      months:["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre	","Octubre","	Noviembre","Diciembre"],
      new_invitation: {
        user_id: this.getUserId(),
        location_id: null,
        valid_from: "",
        valid_until: "",
        init_hour: null,
        finish_hour: null,
        offset_time: new Date().getTimezoneOffset(),
        rut: "",
        phone: "",
        name: "",
        country_phone_code: "57"
      },
      show_load_photo_section: true,
      guest_url_image: null,
      invited_client: {
        id: null,
        name: "",
        state: null,
        photo: null,
      },
      isLoading: false
    }
  },
  watch: {
    'pagination.page': function (val, oldVal) {
      if(oldVal!=val&&val!=null&&oldVal!=null&&val==1&&!this.pagination_query){
        return ''
      }
      else if(oldVal!=val&&val!=null&&oldVal!=null){
        this.pagination_query=true;
        this.getInvitations(false);
      }
    },
    'new_invitation.valid_from': function(){
      this.validateDateRange()
    },
    'new_invitation.valid_until': function(){
      this.validateDateRange()
    },
  },
  methods:{
    modifyDateFormatToStatus(datex){
      var entries=datex.split('/');
      entries=[entries[2],entries[0],entries[1]];
      return entries.join("-");
    },
    newEmailInCourtesy(email_error){
      if(this.newemail!=''&&!email_error){
        this.select.push(this.newemail);
        this.newemail='';
      }
    },
    dateFormat(datex){
        var sections=datex.split("/");
        var index=Number(sections[0])-1;
        sections[0]=this.months[index];
        datex=sections.join(" ");
        return datex;
    },
    monthEarlier(){
      var datex=new Date();
      datex.setMonth(datex.getMonth() - 1);
      return datex.toISOString().substr(0, 10);
    },
    getInvitations(defaultOption, dates){
      // alert("entró"+defaultOption+'query'+this.pagination_query);
      if(this.pagination_query){
        defaultOption=this.saved_default_option;
      }else{
        this.pagination.page=1;
      }
      this.saved_default_option=defaultOption;

      var initialDate = new Date().toISOString().substr(0, 10);
      var lastDate = new Date().toISOString().substr(0, 10);
      if(!defaultOption){
        var object = dates != undefined && dates != null ? dates : {date_from: '', date_to: '' };
        if(object.date_from!='Desde'&&object.date_to!='Hasta'&&object.date_from!=''){
          this.datePickerFirstClick=true;
          this.date = this.modifyDateFormatToStatus(object.date_from);
          this.date2 = this.modifyDateFormatToStatus(object.date_to);
        }
        initialDate=this.date;
        lastDate=this.date2;
      }
      else{
        this.date = new Date().toISOString().substr(0, 10);
        this.date2 = new Date().toISOString().substr(0, 10);
        this.datePickerFirstClick=false;
      }
      try {

        this.$http.get('invitations/get_company_invitations',{
          params:{
            user_id: this.getUserId(),
            page: this.pagination.page,
            first_date: initialDate,
            last_date: lastDate,
            offset_time: new Date().getTimezoneOffset()
          },
          headers:
            {
              "X-Device-ID" : this.getDeviceId(),
              "Authorization" : 'Bearer ' + this.getUserToken()
            }
          }
        ).then(function(response){
          this.invitations= [];
          if(response.body.meta != undefined && response.body.meta != null){
            if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
              this.checkToken(response.body.meta.authentication_token)
            }
          }
          var temp=response.body.data;

          for (var i=0;i<temp.length;i++) {
            this.invitations.push({
              id: temp[i].id,
              name: temp[i].attributes.name,
              rut: temp[i].attributes.rut,
              mobile: temp[i].attributes.mobile,
              location: temp[i].attributes.location_name,
              photo: temp[i].attributes.invited_client_photo,
              invitation_state: temp[i].attributes.invitation_state,
              date_from: this.dateFormat(this.$moment(temp[i].attributes.start_time).format("MM/DD/YYYY - hh:mm A")),
              date_until: this.dateFormat(this.$moment(temp[i].attributes.end_time).format("MM/DD/YYYY - hh:mm A")),
            })
          }
          this.invitations_size=response.body.meta.total_elements;
          this.pages=response.body.meta.pages;
          // console.table(response.body.data)
          this.loaded = true;
          this.date_available=true;
        }, function(response){
          if (response.body.data.message != null &&
              response.body.data.message != undefined &&
              response.body.data.message != '' ){
              alert(response.body.data.message)
          }else{
            alert(response.body.data)
          }
        })
      } catch (e) {
        alert(e.message)
      }
    },
    formatState (state){
      var spanish_state = ''
      if (state === "ACTIVE"){
        spanish_state = "Creada"
      }else if(state === "USED"){
        spanish_state = "Activa"
      }else if(state === "COMPLETED"){
        spanish_state = "Usada"
      }else if(state === "CANCELLED"){
        spanish_state = "Cancelada"
      }else if(state === 'UNUSED'){
        spanish_state = "No Usada"
      }
      return spanish_state
    },
    getCompanyLocations() {
      // const data = { company: { id: this.getCompany().id } };
      // try {
      //   this.$http.post("locations/get_all_company_locations",{ data: this.encrypt(data).toString() },{
      //     headers: {
      //       "X-Device-ID": this.getDeviceId(),
      //       Authorization: "Bearer " + this.getUserToken()
      //     }
      //   }).then(function(response) {
      //     this.locations = response.body.data
      //   });
      // } catch (e) {
      //   alert(e);
      // }
      this.locations =  this.getMonitoredCompanies().find(e => e.id === this.getSelectedCompany()).assigned_locations
    },
    createInvitation(){
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.new_invitation.rut = this.new_invitation.phone
          this.new_invitation.begin_server = new Date(this.new_invitation.valid_from).toISOString().replace('T',' ').replace('Z','')
          this.new_invitation.end_server = new Date(this.new_invitation.valid_until).toISOString().replace('T',' ').replace('Z','')
          this.new_invitation.country_phone_code = "+" + this.new_invitation.country_phone_code
          try {
            this.$http.post('invitations/counter_invitation_creation', {
              data: this.encrypt(this.new_invitation).toString()
            },
            {headers:{
              "X-Device-ID" : this.getDeviceId(),
              "Authorization" : 'Bearer ' + this.getUserToken()
            }}).then(function(response){
              this.datePickerFirstClick2=false;
              this.modal_create=false;
              this.select = [];
              alert(response.body.ans);
              // this.updateIsInAlert(true)
              // this.getInvitations(true);
              this.clearNewInvitationData()
              this.updateShowInvitationsForm(false)
              if(response.body.meta != undefined && response.body.meta != null){
                if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                  this.checkToken(response.body.meta.authentication_token)
                }
              }
            }, function(response){
              if(typeof response.body.errors[0].details != "object"){
                this.alert(response.body.errors[0].details)
                this.updateShowInvitationsForm(false)
              }else{
                let message = []
                for(var key in response.body.errors[0].details){
                  message.push(response.body.errors[0].details[key])
                }
                alert(message);
                this.updateShowInvitationsForm(false)
              }
            })
          } catch (e) {
            alert(e.message)
          }
        }
      });
    },
    openEditPhotoModal(invited_client){
      this.invited_client.id = invited_client.id
      this.invited_client.name = invited_client.name
      this.edit_photo_modal = true
    },
    openCancelModal(invited_client){
      this.invited_client.id = invited_client.id
      this.invited_client.state = "CANCELLED"
      this.modal_cancel = true
    },
    async uploadInvitationPhoto(event){
      console.log(event);

      let vm = this
      const guest_img = event.target.files[0]
      const extension = event.target.files[0].type.split('/')[1]

      console.log("PESO ORIGINAL DE LA FOTO " + guest_img.size)
      console.log("EXTENSION " + extension)

      if(extension != "jpg" && extension != "jpeg"){
        alert('Solo se admite el formato jpg o jpeg')
      } else {
        vm.isLoading = true
        setTimeout(() => vm.isLoading = false, 1000);

        document.querySelector('.invitation__new__scrollable_section').scrollIntoView();

        const blob = await this.compressImage(guest_img, parseInt(10));
        vm.guest_url_image = window.URL.createObjectURL(guest_img);
        vm.show_load_photo_section = false
        vm.error_in_petition = false
        console.log("PESO DE LA FOTO COMPRIMIDA " + blob.size)
        vm.loadUserImage(blob)
      }
    },
    compressImage(imageFile, quality){
      return new Promise((resolve, reject) => {
        const $canvas = document.createElement("canvas")
        const imagen = new Image();
        var oldWidth , oldHeight , newHeight , newWidth = 1000;

        imagen.onload = () => {
          oldWidth = imagen.width;
          oldHeight = imagen.height;
          newHeight = Math.floor(oldHeight / oldWidth * newWidth)
          $canvas.width = newWidth;
          $canvas.height = newHeight;
          $canvas.getContext("2d").drawImage(imagen, 0, 0, newWidth,newHeight);
          $canvas.toBlob(
            (blob) => {
              if (blob === null) {
                return reject(blob);
              } else {
                resolve(blob);
              }
            },
            "image/jpeg",
            quality / 100
          );
        };
        imagen.src = URL.createObjectURL(imageFile);
      });
    },
    loadUserImage(file){
      let reader = new FileReader()
      const vm = this

      reader.readAsDataURL(file);
      reader.onload = () => {
        vm.invited_client.photo = reader.result
      }
    },
    clearInvitedClient(){
      this.show_load_photo_section = true
      this.guest_url_image = null
      this.invited_client.id = null
      this.invited_client.name = null
      this.invited_client.state = null
      this.invited_client.photo = null
    },
    updateInvitation(){
      try {
        this.$http.post('invitations/counter_invitation_update', {
          data: this.encrypt(this.invited_client).toString()
        },
        {headers:{
          "X-Device-ID" : this.getDeviceId(),
          "Authorization" : 'Bearer ' + this.getUserToken()
        }}).then(function(response){
          this.datePickerFirstClick2 = false;
          this.edit_photo_modal = false;
          this.modal_cancel = false;
          this.select= [];
          alert(response.body.ans);
          this.updateIsInAlert(true)
          this.clearInvitedClient()
          this.getInvitations(true);
          if(response.body.meta != undefined && response.body.meta != null){
            if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
              this.checkToken(response.body.meta.authentication_token)
            }
          }
        }, function(response){
          if(typeof response.body.errors[0].details != "object"){
            alert(response.body.errors[0].details);
            this.updateIsInAlert(true)
          }else{
            let message = []
            for(var key in response.body.errors[0].details){
              message.push(response.body.errors[0].details[key])
            }
            alert(message);
            this.updateIsInAlert(true)
          }
        })
      } catch (e) {
        alert(e.message)
      }
    },
    clearNewInvitationData(){
      this.$validator.reset();
      this.new_invitation = {
        user_id: this.getUserId(),
        location_id: null,
        valid_from: "",
        valid_until: "",
        init_hour: null,
        finish_hour: null,
        offset_time: new Date().getTimezoneOffset(),
        rut: "",
        phone: "",
        name: "",
        country_phone_code: "57"
      }
      this.updateShowInvitationsForm(false)
    },
    validateDateRange(){
      let begin = ''
      let end = ''
      let actual_date = new Date()
      actual_date = actual_date.setMinutes(actual_date.getMinutes() - 1);
      if(this.new_invitation.valid_from != ''){
        begin = new Date(this.new_invitation.valid_from).getTime()
      }
      if(this.new_invitation.valid_until){
        end = new Date(this.new_invitation.valid_until).getTime()
      }
      if(begin != '' && begin < actual_date){
        alert('La fecha de ingreso no puede ser inferior a la fecha actual')
        this.updateIsInAlert(true)
        this.new_invitation.valid_from = ''
      }
      if(begin !== '' && end !== ''){
        if(end <= begin){
          alert('La fecha de salida no puede ser inferior o igual a la fecha de entrada');
          this.updateIsInAlert(true)
          this.new_invitation.valid_until = ''
        }
      }
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  },
  mounted(){
    this.date = this.monthEarlier();
    this.getInvitations(true);
    this.getCompanyLocations();
  }
}
</script>

<style lang="css">
</style>
