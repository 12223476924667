<template>
	<article
		v-if="canRecord"
		class="audio_recording">
		<section
			v-if="!onRecording"
			class="audio_recording__section principal">
			<div
				class="audio_recording__container_icon"
				@click="startAudio()">
				<microphone-icon></microphone-icon>
				<p>Enviar Audio</p>
			</div>
		</section>
		<section
			v-else
			class="audio_recording__section">
			<div
				class="audio_recording__container_icon"
				@click="cancelAudio()">
				<cancel-audio-icon></cancel-audio-icon>
				<p>Cancelar</p>
			</div>
			<div
				class="audio_recording__counter"
				id="counter">
				00:00
			</div>
			<div
				class="audio_recording__container_icon"
				@click="finishAudio()">
				<send-audio-icon></send-audio-icon>
				<p>Enviar</p>
			</div>
		</section>
		<!-- <div v-if="audio.length > 0">
			<div @click="() => (audio = [])">
				cancelar
			</div>
			<audio controls :src="audioSrc"></audio>
		</div> -->
	</article>
</template>

<script>
	export default {
		name: "AudioRecorder",
		props: {},
		data() {
			return {
				canRecord: true,
				onRecording: false,
				audio: [],
				mediaRecorder: null,
				mediaStream: null,
				audioSrc: null,
				counter: null,
				minutes: 0,
				seconds: 0,
				remove_audio: false
			};
		},
		watch: {
			audio: function(audio) {
				console.log("!!!!!!!!!!!!!!!AUDIO!!!!!!!!!");
				console.log(audio);
				if (audio.length > 0 && !this.remove_audio) {
					let audioBytes = new Blob(audio, {
						type: "audio/mp3;",
					});
					this.audioSrc = window.URL.createObjectURL(audioBytes);
					this.stopCounter()
					this.$emit("audio-load", audioBytes);
				}
				// else {
				// 	this.$emit("audio-load", null);
				// }
			},
			onRecording: async function(recording) {
				if (recording) {
					if (this.mediaStream) {
						this.mediaStream
							.getTracks()
							.forEach((track) => track.stop());
					}

					const mediaStreamObj = await navigator.mediaDevices.getUserMedia(
						{
							audio: true,
						}
					);
					this.mediaStream = mediaStreamObj;
					this.mediaRecorder = new MediaRecorder(this.mediaStream);
					this.audio = [];
					this.mediaRecorder.start();
					let vm = this;
					this.mediaRecorder.ondataavailable = (event) => {
						vm.audio.push(event.data);
					};
				} else {
					this.mediaRecorder.stop();
					this.mediaStream
						.getTracks()
						.forEach((track) => track.stop());
					this.mediaStream = null;
				}
			},
		},
		methods: {
			reset() {
				this.audio = [];
			},
			cancelAudio(){
				this.remove_audio = true
				this.stopCounter();
				this.counter = null;
				this.audio = []
				this.onRecording = false
				this.$emit("cancel-audio", false)
			},
			finishAudio(){
				this.remove_audio = false
				this.stopCounter();
				this.counter = null;
				this.onRecording = false
			},
			startCounter(){
				var vm = this
				this.minutes = 0
				this.seconds = 0
				this.counter = setInterval(function(){
					vm.seconds++;
					if (vm.seconds == 60) {
						vm.seconds = 0;
						vm.minutes++;
					}
					vm.updateCounter();
				}, 1000); // Actualiza el contador cada segundo
			},
			stopCounter(){
				var vm = this
				window.clearInterval(vm.counter);
			},
			updateCounter(){
				document.getElementById('counter').innerText = (this.minutes < 10 ? '0' : '')+this.minutes + ':' + (this.seconds < 10 ? '0' : '') + this.seconds;
			},
			startAudio(){
				this.$emit("start-audio", true)
				this.onRecording = true
				this.startCounter()
			}
		},
		async mounted() {
			// this.startCounter()
			try {
				const mediaStreamObj = await navigator.mediaDevices.getUserMedia(
					{
						audio: true,
					}
				);
				mediaStreamObj.getTracks().forEach((track) => track.stop());
			} catch (error) {
				this.canRecord = false;
				console.log(error.name, error.message);
			}
		},
	};
</script>
