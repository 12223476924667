<template>
  <div :class="{'container' : true, 'container--off' : fastSpeed}">
    <!-- :class="{'container--off' : loadSpeed == 'fast', 'container--background' : eventImage === ''}" -->
    <img
      v-show="eventImageLoaded"
      class="event_image"
      :src="eventImage"
      alt="event_image"
      ref="event_img">

    <div class="event_loader">
      <span
        :class="{
          'event_loader__text' : true,
          'event_loader__text--ligth': eventImageLoaded
        }">
        Cargando evento
      </span>
      <div
      :class="{
        'event_loader__loader' : true,
        'event_loader__loader--ligth': eventImageLoaded
      }">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "EventLoader",
    props: {
      eventImage: {
        type: String,
        default: ''
      },
      fastSpeed: {
        type: Boolean,
        default: false
      }
    },
    data(){
      return {
        eventImageLoaded: false
      }
    },
    watch: {
      eventImage(newValue) {
        if(newValue === '') this.eventImageLoaded = false;
      }
    },
    mounted () {
      console.log("EVENT IMAGE LOADED", this.eventImageLoaded);
      this.$refs.event_img.addEventListener('load', () => this.eventImageLoaded = true);
    },
  }
</script>

<style lang="scss">
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: 10;
    background: rgb(161,194,255);
    background: linear-gradient(0deg, rgba(161,194,255,1) 0%, rgba(218,227,244,1) 100%);

    &--off {
      animation: fade-out 0.5s forwards;
    }
  }
  .event_image {
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    position: absolute;
    border-radius: 5px;
    animation: fade-in 0.5s forwards;
  }
  .event_loader {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__text {
      font-size: 22px;
      font-weight: bold;
      animation: fade-loop 3s infinite;
      color: #3c80f5;
      margin-bottom: 1rem;

      &--ligth {
        color: #c6daff;
        text-shadow: 0 0 2px #000;
      }
    }

    &__loader {
      width: 50px;
      aspect-ratio: 1;
      border-radius: 50%;
      background: 
        radial-gradient(farthest-side,#3c80f5 94%,#0000) top/8px 8px no-repeat,
        conic-gradient(#0000 30%,#3c80f5);
      -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
      animation: l13 1s infinite linear;

      &--ligth {
        background: 
          radial-gradient(farthest-side,#c6daff 94%,#0000) top/8px 8px no-repeat,
          conic-gradient(#0000 30%,#c6daff);
      }
    }
  }

  @keyframes l13{ 
    100%{transform: rotate(1turn)}
  }

  // ANIMATIONS
  @keyframes fade-in {
    0% {
      opacity: 0;
      visibility: hidden;
    }
    100% {
      opacity: 1;
      visibility: visible;
    }
  }
  @keyframes fade-out {
    70% {
      opacity: 1;
      visibility: visible;
    }
    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
  // @keyframes fade-loop {
  //   0% {
  //     opacity: 1;
  //     visibility: visible;
  //   }
  //   50% {
  //     opacity: 0;
  //     visibility: hidden;
  //   }
  //   100% {
  //     opacity: 1;
  //     visibility: visible;
  //   }
  // }
</style>