<template>
  <svg id="Capa_5" data-name="Capa 5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.18 78.18">
    <path :fill="color" d="m54.17,39.06c-.04,2.21-.78,4.11-2.3,5.65-4.01,4.04-8.05,8.07-12.08,12.09-.96.96-2.09,1.18-3.18.67-1.02-.47-1.66-1.54-1.58-2.66.06-.83.5-1.47,1.08-2.04,3.84-3.81,7.67-7.62,11.51-11.43,1.58-1.57,1.58-2.94.01-4.49-3.85-3.83-7.7-7.65-11.55-11.48-.84-.83-1.29-1.78-.93-2.97.55-1.83,2.66-2.53,4.21-1.41.2.15.38.32.56.49,3.94,3.94,7.88,7.88,11.82,11.82,1.6,1.6,2.41,3.54,2.45,5.75Z"/>
  </svg>
</template>

<script>
export default {
  props:{
    color:{
      default: '#999',
      type: String
    }
  }
}
</script>
