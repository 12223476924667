<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 800 800">
    <g
      id="Group_1"
      data-name="Group 1">
      <path
        id="Path_1"
        data-name="Path 1"
        d="M0,400C0,179.09,179.09,0,400,0s400,179.09,400,400-179.09,400-400,400S0,620.91,0,400Z"
        style="fill: #FFCCBC; stroke-width: 0px;"/>
    </g>
    <g
      id="Group_3"
      data-name="Group 3">
      <g
        id="Group_2"
        data-name="Group 2">
        <path
          id="Path_2"
          data-name="Path 2"
          d="M324.88,213.94v35.75h-35.75l17.69-17.69,18.06-18.06Z"
          style="fill: #343434; stroke-width: 0px;"/>
        <path
          id="Path_3"
          data-name="Path 3"
          d="M650,400.19v231.06c0,10.36-8.39,18.75-18.75,18.75H168.75c-8.02,0-15.15-5.1-17.74-12.69-.68-1.9-1.02-3.91-1.01-5.93v-231.19c0-5.69,2.57-11.07,7-14.63l68.19-54.5v-62.69c0-4.96,1.96-9.72,5.44-13.24l99.75-99.63c3.52-3.5,8.28-5.48,13.25-5.49h212.44c10.36,0,18.75,8.39,18.75,18.75v164.44l67.87,52.12c4.62,3.56,7.32,9.05,7.32,14.88ZM612.5,597.06v-164.76l-121.06,68.56,121.06,96.19ZM574.81,410.56l22.62-12.75-22.62-17.37v30.12ZM223.5,612.56h348.25l-171.87-136.57-176.37,136.57ZM537.31,431.81v-244.37h-174.94v80.94c0,10.36-8.39,18.75-18.75,18.75h-80.94v146.37l74.51,43.62,51.31-39.74c6.83-5.31,16.41-5.26,23.18.12l48.12,38.25,77.5-43.93ZM324.88,249.69v-35.75l-18.06,18.06-17.69,17.69h35.75ZM187.5,593.06l117.75-91.19-117.75-69.01v160.19ZM225.19,411.49v-32.44l-23.44,18.75,23.44,13.69Z"
          style="fill: #343434; stroke-width: 0px;"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props:{
    color: {
      type: String,
      default: "#8BC34A"
    }
  }
};
</script>

<style lang="css">
</style>
