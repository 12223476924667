<template>
  <!-- <v-dialog v-model="showModal" width="20%" persistent hide-overlay no-click-animation> -->
  <v-dialog
    class="change-position "
    v-model="showModal"
    width="20%"
    left="100px"
    persistent
    hide-overlay
    no-click-animation
  >
    <v-card class="incoming_call">
      <v-card-title> </v-card-title>
      <div class="incoming_call__avatar">
        <img src="@/assets/img/avatar.png" class="incoming_call__avatar--img" />
      </div>
      <div class="incoming_call__text">
        <h4>Llamada entrante</h4>
        <p>{{ sublocationInfo.company_name }}</p>
        <p>{{ sublocationInfo.location_name }}</p>
        <p>{{ sublocationInfo.sublocation_name }}</p>
      </div>
      <div class="incoming_call__call_buttons">
        <img
          class="incoming_call__call_buttons--button"
          src="@/assets/img/call.png"
          @click="answerCall()"
        />
        <img
          class="incoming_call__call_buttons--button"
          src="@/assets/img/hang.png"
          @click="hangUp()"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      audio: null,
    };
  },
  props: ["showModal", "sublocationId", "sublocationInfo"],

  methods: {
    makeDialogsDraggable() {
      const d = {};
      document.addEventListener("mousedown", (e) => {
        const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
        if (
          e.button === 0 &&
          closestDialog != null &&
          e.target.classList.contains("v-card__title")
        ) {
          d.el = closestDialog;
          d.mouseStartX = e.clientX;
          d.mouseStartY = e.clientY;
          d.elStartX = d.el.getBoundingClientRect().left;
          d.elStartY = d.el.getBoundingClientRect().top;
          d.el.style.position = "fixed";
          d.el.style.margin = 0;
          d.oldTransition = d.el.style.transition;
          d.el.style.transition = "none";
        }
      });
      document.addEventListener("mousemove", (e) => {
        if (d.el === undefined) return;
        d.el.style.left =
          Math.min(
            Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
            window.innerWidth - d.el.getBoundingClientRect().width
          ) + "px";
        d.el.style.top =
          Math.min(
            Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
            window.innerHeight - d.el.getBoundingClientRect().height
          ) + "px";
      });
      document.addEventListener("mouseup", () => {
        if (d.el === undefined) return;
        d.el.style.transition = d.oldTransition;
        d.el = undefined;
      });
      setInterval(() => {
        const dialog = document.querySelector(".v-dialog.v-dialog--active");
        if (dialog === null) return;
        dialog.style.left =
          Math.min(
            parseInt(dialog.style.left),
            window.innerWidth - dialog.getBoundingClientRect().width
          ) + "px";
        dialog.style.top =
          Math.min(
            parseInt(dialog.style.top),
            window.innerHeight - dialog.getBoundingClientRect().height
          ) + "px";
      }, 100);
    },

    answerCall() {
      console.log("----------> answer call");
      this.$emit("show-video-call-modal");
      const room = `room_virtual_guard_${this.sublocationId}`;
      this.$cable.perform({
        channel: "VirtualGuardChannel",
        action: "send_message",
        data: {
          room: room,
          message: "answerCall",
          answer_call: true,
          sublocation_id: this.sublocationId,
          connection_id: this.getUserId(),
        },
      });
    },

    hangUp() {
      console.log("--------> hang up call");
      this.$emit("close-modal");
      const room = `room_virtual_guard_${this.sublocationId}`;
      this.$cable.perform({
        channel: "VirtualGuardChannel",
        action: "send_message",
        data: {
          room: room,
          message: "hangUp",
          calling: false,
          sublocation_id: this.sublocationId,
          connection_id: this.getUserId(),
        },
      });
    },
  },

  watch: {
    showModal(newVal) {
      console.log("showModal changed:", newVal);

      // if (newVal) {
      //   this.audio = new Audio(require("@/assets/audio/incomingCall.mp3"));
      //   // this.showModalActive = true;
      //   this.audio.play();
      // } else {
      //   this.audio.pause();
      // }
      if (newVal == false) {
        this.audio.pause();
      }
    },
  },

  mounted() {
    console.log("---------------->showModal", this.showModal);
    if (this.showModal) {
      this.audio = new Audio(require("@/assets/audio/incomingCall.mp3"));
      this.audio.play();
    } else {
      this.audio.pause();
    }
    this.makeDialogsDraggable();
  },

  beforeDestroy() {
    if (this.audio) {
      this.audio.pause();
      this.audio = null;
    }
  },
};
</script>

<style scoped>
.change-position {
  position: absolute;
  left: 0px;
}
</style>
