<template>
	<article class="monitoring__main_section">
		<div class="monitoring__drawer">
			<v-list-item class="monitoring__container--header">
				<v-list-item-content>
					<v-list-item-title
						class="text-h4 clients_head"
						title="Eventos">
						<component v-bind:is="getCurrentSection().icon"></component>
						<p>Eventos</p>
                        <div class="clients_head__notification" @click="monitoringView()" v-if="getPendingLocations().length > 0">
                            <span>{{ getPendingLocations().length }}</span>
                        </div>
						<!-- <div
							class="clients_head__arrow"
							:class="{'clients_head__arrow--active': getShowCompanySections()}"
							@click="updateShowCompanySections(!getShowCompanySections())">
							<drop-down-arrow></drop-down-arrow>
						</div> -->
					</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
			<v-divider></v-divider>
			<v-list class="monitoring__locations_container">
				<company-sections v-if="show_company_sections"></company-sections>
			</v-list>
		</div>
		<div class="monitoring__events_panel">
			<div class="monitoring__events_header">				
				<div class="monitoring__events_header--info_section">
					<span v-if="selected_location !== null">{{ selected_location_name }} - ({{ selected_company_name }})</span>
				</div>
				<div class="monitoring__events_header--date_time_section">
					<span>{{ date }}</span><span>{{ time }}</span>
				</div>
			</div>
			<div class="monitoring__events_management">
				
				<div class="monitoring__events_gallery">
					
				</div>
			</div>
		</div>

        <div class="monitoring__main_section--big_notification" @click="monitoringView()" v-if="getPendingLocations().length > 0">
            <span>{{ getPendingLocations().length }}</span>
        </div>

		<incoming-call-modal
			v-for="(incomingCall, index) in incoming_calls"
			:key="index"
			:show-modal="incomingCall.show"
			:sublocation-id="incomingCall.sublocation_id"
			@show-video-call-modal="answerCall(index)"
			@close-modal="closeModal(index)"
			:sublocation-info="incomingCall.sublocation_info">
		</incoming-call-modal>
		<video-call-modal
			:show-modal="show_video_call_modal"
			:sublocation-id="incoming_call_sublocation_id"
			@close-video-call-modal="restoreModals"
			:incoming-call-on-call="incoming_call_on_call"
			@restore-incoming-call-on-call="restoreIncomingCallOnCall">
		</video-call-modal>
		<sos-alert-modal
			:user_data="current_sos_alert"
			v-if="getSOSAlert()">
		</sos-alert-modal>
		<speaker-section v-if="getShowSpeakers()"></speaker-section>
		<invitations-form v-if="getShowInvitationsForm()"></invitations-form>
		<open-doors v-if="getShowOpenDoors()"></open-doors>
	</article>
</template>

<script>
import moment from 'moment';
import CircleIcon from "@/components/shared/icons/CircleIcon.vue";
import CircleCheckIcon from "@/components/shared/icons/CircleCheckIcon.vue";
import ArrowCircleRightIcon from "@/components/shared/icons/ArrowCircleRightIcon.vue";
import IncomingCallModal from "./modals/IncomingCallModal.vue";
import VideoCallModal from "./modals/VideoCallModal.vue";
import VideoPlayer from "@/components/shared/VideoPlayer.vue";
import EventLoader from "@/components/shared/EventLoader.vue";
import SOSAlert from "@/components/shared/SOSAlert.vue";
import CheckboxEmpty from "@/components/shared/icons/CheckboxEmpty.vue";
import CheckboxDone from "@/components/shared/icons/CheckboxDone.vue";
import PlayIcon from '@/components/shared/icons/PlayIcon.vue'
import CheckIcon from '@/components/shared/icons/CheckIcon.vue'
    export default {
        data(){
            return{
                date: null,
                time: null,
                selected_location: null,
                selected_location_name: '',
                selected_company_name: '',
                incoming_calls: [],
                on_call: false,
                show_incoming_call_modal: false,
                show_video_call_modal: false,
                incoming_call_sublocation_id: '',
                incoming_call_on_call: false,
                show_company_sections: false
            }
        },
        mounted(){
            
        },
        created(){
            console.log('-----> Created Component Interaction!!!')
            this.connection_id = this.getUserId()
            if (this.$route.params.company_id !== undefined && this.$route.params.location_id !== undefined){
                this.selectLocation(this.$route.params.company_id, this.$route.params.location_id)
            }

            setInterval(() => this.updateCurrentTime(), 1000);
        },
        channels: {
            VirtualGuardChannel: {
                received(data) {
                    if(data){
                        const connectionId = data.data.connection_id;
                        // Verifica si el mensaje proviene de la misma conexión (emisor)
                        if (connectionId !== this.connection_id) {
                            console.log('------> data from digital guard', data)
                            if(data.data.calling == true){
                                this.incoming_call_sublocation_id = data.data.sublocation_id
                                this.sublocation_info = data.data.sublocation_info
                                var call = {}
                                call.show = true
                                call.sublocation_id = data.data.sublocation_id
                                call.sublocation_info = data.data.sublocation_info
                                if (!this.incoming_calls.includes(call)){
                                    this.incoming_calls.push(call)
                                }
                                console.log('------------------>this.incoming_calls', this.incoming_calls)
                                this.show_incoming_call_modal = true
                                if(this.on_call == true){
                                    console.log('on calling ---->')
                                    this.onCall(data.data.sublocation_id)
                                }
                                this.updateInCallState(true)
                            }else if (data.data.notify_call_ended == true) {
                                console.log("-----------> notify call ended");
                                this.restoreModals();
                            }else{
                                if(data.data.calling === false && data.data.message === "hangUp"){
                                    this.updateInCallState(false)
                                }
                                const matchingIncomingCalls = this.incoming_calls.filter(incomingCall => incomingCall.sublocation_id === data.data.sublocation_id);
                                console.log('matchingIncomingCalls--------->', matchingIncomingCalls);
                                matchingIncomingCalls.forEach(match => {
                                    const index = this.incoming_calls.indexOf(match);
                                    if (index !== -1) {
                                        this.incoming_calls.splice(index, 1);
                                    }
                                });
                            }
                        }
                    }
                }
            },
            EventsMonitoringChannel: {
                received(data) {
                    console.log("llega un evento por el canal de monitor!", data);
                    if((data.message.suspended_event != true || data.message.suspended_event === undefined) && data.message.event_code != "FUSE-ALERT-SOS"){
                        let audio = new Audio(require("@/assets/audio/monitoring_notification.wav"));
                        audio.play();
                        let company_index = this.getMonitoredCompanies().findIndex(e => e.id === data.message.company_id)
                        this.getMonitoredCompanies()[company_index].pending_events += 1
                        let location_index = this.getMonitoredCompanies()[company_index].assigned_locations.findIndex(e => e.id === data.message.location_id)
                        this.getMonitoredCompanies()[company_index].assigned_locations[location_index].pending_events += 1
                        let location = this.getMonitoredCompanies()[company_index].assigned_locations[location_index]
                        location_index = this.getPendingLocations().findIndex(e => e.id === location.id)
                        if(location_index === -1){
                            let loc_object = {
                                id: location.id,
                                name: location.name,
                                event_queue: [],
                                pending_events: 1,
                                speaker: location.speaker,
                                company_id: this.getMonitoredCompanies()[company_index].id
                            }
                            this.getPendingLocations().unshift(loc_object)
                            this.getMonitoredCompanies()[company_index].monitored_locations.unshift(loc_object)
                        }else{
                            let pending_events_count = this.getPendingLocations()[location_index].pending_events + 1
                            this.getPendingLocations().splice(location_index, 1)
                            let monitored_loc_index = this.getMonitoredCompanies()[company_index].monitored_locations.findIndex(e => e.id === data.message.location_id)
                            this.getMonitoredCompanies()[company_index].monitored_locations.splice(monitored_loc_index, 1)
                            let loc_object = {
                                id: location.id,
                                name: location.name,
                                event_queue: [],
                                pending_events: pending_events_count,
                                speaker: location.speaker,
                                company_id: this.getMonitoredCompanies()[company_index].id
                            }
                            this.getPendingLocations().unshift(loc_object)
                            this.getMonitoredCompanies()[company_index].monitored_locations.unshift(loc_object)
                        }
                    }
                    if(["FUSE-ALERT-SOS"].includes(data.message.event_code)) {
                        let audio = new Audio(require("@/assets/audio/monitoring_notification.wav"));
                        audio.play();
                        console.log("Event Code Excluded !!!!!!!!!! ", data.message.event_code);
                        this.current_sos_alert = data.message.text_message
                        this.updateSOSAlert(true)
                    }
                }
            },
        },
        components: {
            "circle-icon" : CircleIcon,
            "circle-check-icon" : CircleCheckIcon,
            "arrow-circle-right-icon" : ArrowCircleRightIcon,
            "incoming-call-modal" : IncomingCallModal,
            "video-call-modal" : VideoCallModal,
            VideoPlayer,
            EventLoader,
            "sos-alert-modal" : SOSAlert,
            "checkbox-empty" : CheckboxEmpty,
            "checkbox-done" : CheckboxDone,
            "play-icon" : PlayIcon,
            "check-icon" : CheckIcon
        },
        watch:{
            $route (to){
                this.show_company_sections = false
                this.hikcentral_streaming_link = this.getMonitoredCompanies().find(e => e.id === this.getSelectedCompany()).hikcentral_streaming_link
                if (to.params.company_id !== undefined && to.params.location_id !== undefined){
                    this.selectLocation(to.params.company_id, to.params.location_id)
                }
            },
        },
        methods:{            
            selectLocation(company_id, location_id){
                this.show_company_sections = true
                this.selected_location = location_id
                let company_index = this.getMonitoredCompanies().findIndex(e => e.id === company_id)
                let location_index = this.getMonitoredCompanies()[company_index].assigned_locations.findIndex(e => e.id === location_id)
				this.selected_company_name = this.getMonitoredCompanies()[company_index].name
				this.selected_location_name = this.getMonitoredCompanies()[company_index].assigned_locations[location_index].name
            },
            answerCall(index) {
                console.log('--> answer calls')
                // this.show_incoming_call_modal = false;
                this.incoming_calls[index].show = false
                this.show_video_call_modal = true;
                if(this.on_call == true){
                    this.incoming_call_on_call = true
                }
                this.on_call = true
            },
            restoreModals(){
                this.show_video_call_modal = false
                this.on_call = false
                this.incoming_call_on_call = false
                this.updateInCallState(false)
            },
            restoreIncomingCallOnCall(){
                this.incoming_call_on_call = false
            },
            closeModal(index){
                console.log('Index', index)
                this.incoming_calls[index].show = false
                // this.show_incoming_call_modal = false;
                this.incoming_calls.splice(index, 1)
                this.updateInCallState(false)
            },
            monitoringView(){
                if(!this.getInCallState()){
                    this.$router.push({name: 'monitoring'});
                    console.log('-----> Get Show Drawer Variable:', this.getShowDrawer())
                    this.updateShowDrawer(!this.getShowDrawer())
                }
            },
            updateCurrentTime() {      
                var day = moment().format("DD ")
                var month = moment().format("MMMM ").toString()
                var capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
                var year = moment().format("YYYY ")
                this.date = day + capitalizedMonth + year
                this.time = moment().format("h:mm a");
            },
            subscribeToMonitorChannel() {
                this.unsubscribeFromMonitorChannel()
                console.log('Entra a suscribirse al canal del Monitor en interaction!!!', this.getUserId())
                // Suscribirse al canal del monitor
                this.$cable.subscribe({
                    channel: "EventsMonitoringChannel",
                    room: `room_events_monitoring_${this.getUserId()}`,
                    monitor_id: this.getUserId(),
                });
            },
            unsubscribeFromMonitorChannel() {
                this.$cable.unsubscribe("EventsMonitoringChannel")
            },
            subscribeVirtualGuardChannel(){
                // this.unsubscribeVirtualGuardChannel()
                console.log('subscribeVirtualGuardChannel')
                var companies = this.getMonitoredCompanies()
                companies.forEach(company => {
                    console.log('-----------> company', company)
                    if(company.monitoring_calls){
                        company.assigned_locations.forEach(location => {
                            location.sublocations.forEach(sublocation => {
                                if(sublocation.enable_calls){
                                    console.log('Se suscribe al canal para la sublocación', sublocation.id)
                                    this.$cable.subscribe({
                                        channel: "VirtualGuardChannel",
                                        room: `room_virtual_guard_${sublocation.id}`,
                                        sublocation_id: sublocation.id,
                                    });
                                }
                            });
                        });
                    }
                });
            },
            unsubscribeVirtualGuardChannel() {
                this.$cable.unsubscribe("VirtualGuardChannel")
            }            
        }
    }
</script>