<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 799 799">
    <path
      id="Path_20"
      data-name="Path 20"
      d="M0,399.5C0,178.86,178.86,0,399.5,0s399.5,178.86,399.5,399.5-178.86,399.5-399.5,399.5S0,620.14,0,399.5Z"
      style="fill: #BBDEFB; stroke-width: 0px;"/>
    <g>
      <path
        d="M451.28,405.41c-19.02,0-37.61-5.64-53.42-16.21-15.81-10.56-28.14-25.58-35.41-43.15-7.28-17.57-9.18-36.9-5.47-55.56,3.71-18.65,12.87-35.78,26.32-49.23,13.45-13.45,30.58-22.61,49.23-26.32,18.65-3.71,37.98-1.81,55.56,5.47,17.57,7.28,32.59,19.6,43.15,35.41,10.57,15.81,16.21,34.4,16.21,53.42,0,25.5-10.13,49.96-28.16,67.99-18.03,18.03-42.49,28.16-67.99,28.16ZM451.28,251.56c-11.41,0-22.56,3.38-32.05,9.72-9.49,6.34-16.88,15.35-21.25,25.89-4.37,10.54-5.51,22.14-3.28,33.33,2.23,11.19,7.72,21.47,15.79,29.54,8.07,8.07,18.35,13.56,29.54,15.79,11.19,2.23,22.79,1.08,33.33-3.28,10.54-4.37,19.55-11.76,25.89-21.25,6.34-9.49,9.72-20.64,9.72-32.05,0-15.3-6.08-29.98-16.9-40.79-10.82-10.82-25.49-16.9-40.79-16.9Z"
        style="fill: #343434; stroke-width: 0px;"/>
      <path
        d="M630.77,584.9c-5.08-.07-9.93-2.11-13.53-5.71s-5.64-8.45-5.71-13.53c0-50-27.18-83.33-160.26-83.33s-160.26,33.33-160.26,83.33c0,5.1-2.03,9.99-5.63,13.6-3.61,3.61-8.5,5.63-13.6,5.63s-9.99-2.03-13.6-5.63c-3.61-3.61-5.63-8.5-5.63-13.6,0-121.79,139.23-121.79,198.72-121.79s198.72,0,198.72,121.79c-.07,5.08-2.11,9.93-5.71,13.53s-8.45,5.64-13.53,5.71Z"
        style="fill: #343434; stroke-width: 0px;"/>
      <path
        d="M305.64,426.18h-8.21c-20.4-1.97-39.18-11.97-52.21-27.79-13.03-15.82-19.24-36.17-17.27-56.57,1.97-20.4,11.97-39.18,27.79-52.21,15.82-13.03,36.17-19.24,56.57-17.27,2.62.11,5.18.76,7.54,1.9,2.36,1.14,4.46,2.75,6.17,4.73,1.71,1.98,3.01,4.29,3.8,6.78.79,2.5,1.06,5.13.8,7.73s-1.05,5.13-2.33,7.42c-1.27,2.29-3,4.29-5.07,5.89-2.07,1.6-4.45,2.76-6.99,3.4-2.54.65-5.18.77-7.77.36-5.01-.5-10.06.02-14.86,1.51-4.8,1.5-9.25,3.95-13.09,7.2-3.89,3.14-7.12,7.03-9.5,11.43-2.38,4.4-3.85,9.23-4.35,14.21-.53,5.04-.05,10.14,1.41,14.99,1.46,4.86,3.87,9.37,7.09,13.28,3.22,3.92,7.19,7.15,11.67,9.51,4.48,2.36,9.39,3.81,14.44,4.26,8.35.72,16.7-1.26,23.85-5.64,4.35-2.68,9.59-3.53,14.57-2.35,4.98,1.18,9.28,4.28,11.97,8.64,2.69,4.35,3.53,9.59,2.36,14.57-1.18,4.98-4.29,9.28-8.64,11.97-11.89,7.58-25.64,11.75-39.74,12.05Z"
        style="fill: #343434; stroke-width: 0px;"/>
      <path
        d="M169.23,565.67c-5.08-.07-9.93-2.11-13.52-5.71-3.59-3.59-5.64-8.45-5.71-13.53,0-69.23,18.46-115.38,108.97-115.38,5.1,0,9.99,2.03,13.6,5.63,3.61,3.61,5.63,8.5,5.63,13.6s-2.03,9.99-5.63,13.6c-3.61,3.61-8.5,5.63-13.6,5.63-60.26,0-70.51,19.23-70.51,76.92-.07,5.08-2.11,9.93-5.71,13.53-3.59,3.59-8.45,5.64-13.52,5.71Z"
        style="fill: #343434; stroke-width: 0px;"/>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
