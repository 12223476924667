<template>
  <article class="sos_alert">
    <section class="sos_alert__container">
      <div @click="closeSOSModal()" class="sos_alert__close">
        <close-icon ></close-icon>
      </div>
      <div class="sos_alert__content">
        <alert-icon :color="'#C62828'"></alert-icon>
        <p v-html="user_data"></p>
      </div>
    </section>
  </article>
</template>

<script>
import CloseIcon from "@/components/shared/icons/CloseIcon.vue";
import AlertIcon from "@/components/shared/icons/SOSIcon.vue";
export default {
  props:{
    user_data:{
      type: String,
      default: ''
    }
  },
  components: {
    "close-icon" : CloseIcon,
    "alert-icon" : AlertIcon,
  },
  methods:{
    closeSOSModal(){
      this.updateSOSAlert(false)
    }
  }
}
</script>
