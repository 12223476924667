<template>
  <svg
    width="800px"
    height="800px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Arrow / Arrow_Right_SM">
      <path
        id="Vector"
        d="M7 12H17M17 12L13 8M17 12L13 16"
        stroke="#FFFFFF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>
