<template>
  <svg
    height="800px"
    width="800px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xml:space="preserve"
    fill="#000000">
    <g
      id="SVGRepo_bgCarrier"
      stroke-width="0"/>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"/>
    <g
      id="SVGRepo_iconCarrier">
      <path
        style="fill:#a9a7a7;"
        d="M512,256.006C512,397.402,397.394,512.004,256.004,512C114.606,512.004,0,397.402,0,256.006 C-0.007,114.61,114.606,0,256.004,0C397.394,0,512,114.614,512,256.006z"/>
      <path
        style="fill:#9f9d9d;"
        d="M488.646,362.818c-0.219-0.23-118.568-118.614-118.98-118.978c-0.395-0.358-0.725-0.772-1.137-1.137 c-0.395-0.358-0.725-0.772-1.134-1.134c-0.399-0.362-0.73-0.777-1.143-1.143c-0.394-0.358-0.724-0.771-1.135-1.135c-0.395-0.358-0.726-0.772-1.125-1.127c-22.506-19.964-49.361-30.122-73.329-35.139c-0.031-0.034-0.071-0.059-0.105-0.093 c-0.279-0.468-58.588-59.054-59.887-59.667c-2.485-1.178-5.409-0.803-7.532,0.942l-120.503,99.237c-1.634,1.343-2.582,3.35-2.582,5.469s0.948,4.126,2.582,5.469c0,0,239.812,239.846,240,240l2.165,1.783 C408.622,472.561,460.405,424.232,488.646,362.818z"/>
      <path
        style="fill:#efebeb;"
        d="M363.991,238.162c-44.964-39.887-107.299-40.634-129.258-39.443v-49.044 c0-2.741-1.582-5.241-4.063-6.41c-2.485-1.177-5.41-0.803-7.532,0.942l-120.503,99.236c-1.634,1.343-2.582,3.35-2.582,5.469 s0.948,4.126,2.582,5.469l120.503,99.238c2.122,1.744,5.05,2.118,7.532,0.942c2.482-1.17,4.063-3.669,4.063-6.41v-51.198 c23.066-5.302,116.402-20.289,163.869,68.711c1.253,2.347,3.683,3.751,6.254,3.751c0.567,0,1.146-0.069,1.721-0.214c3.153-0.789,5.368-3.62,5.368-6.874C411.947,308.168,395.813,266.391,363.991,238.162z M273.167,278.928 c-27.306,0-47.027,5.572-47.522,5.717c-3.014,0.886-5.088,3.655-5.088,6.798v41.686l-102.263-84.217l102.263-84.217v41.686 c0,2.028,0.868,3.959,2.385,5.302c1.519,1.343,3.551,1.987,5.548,1.738c0.762-0.103,76.547-8.611,126.094,35.345c23.359,20.725,37.329,49.945,41.697,87.076C360.333,288.993,309.887,278.928,273.167,278.928z"/>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style lang="css">
</style>
