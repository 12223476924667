<template>
  <div id="container">
    <video ref="videoPlayer" class="video-js video_player"></video>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    expandedEvent: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      player: null
    }
  },
  watch: {
    expandedEvent(newValue){
      if(newValue){
        this.player.requestFullscreen();
      }
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.log('onPlayerReady');
    });

    this.player.on('fullscreenchange', () => {
      if(!this.player.isFullscreen()){
        this.$emit('close-full-screen')
      }
    })
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}
</script>

<style lang="scss">
  .video_player {
    width: 100%;
    height: 280px;
    border-radius: 5px;
    z-index: 9;

    @media (min-width: 1600px) {
      height: 380px;
    }

    @media (min-width: 1800px) {
      height: 360px;
    }
  }
</style>