<template>
  <div
    class="image_loader__content">
    <div
      class="image_loader"
      v-show="!image_charge">
      <div
        :class="{
          'event_loader__loader' : true,
          'event_loader__loader--ligth': image_charge
        }">
      </div>
    </div>
    <div v-show="image_charge">
      <img
        v-if="is_image"
        :src="src"
        alt="">

      <div class="" v-else>
        <video :id="event_id" :src="url" type="video/mp4" controls hidden></video>
        <!-- <button onclick="capture()">Capture</button> -->
        <canvas :id="'canvas'+event_id" style="overflow:auto"></canvas>
      </div>
      <!-- <video-thumbnail
        v-else
        :video-src="url"
        :cors="true"></video-thumbnail> -->

      <!-- <iframe v-else :src="url+'?controls=0'" ></iframe> -->
    </div>

  </div>
</template>

<script>
export default {
  data(){
    return{
      image_charge: false,
      src: '',
      is_image: true
    }
  },
  methods: {
    capture() {
      var vm = this
      console.log("!!!!!!!!!!!!!!!!!! Event Id");
      console.log(vm.event_id);
      var canvas = document.getElementById('canvas'+vm.event_id)
      var video = document.getElementById(vm.event_id)
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight)
      canvas.toBlob( (blob) => {
        const img = new Image()
        img.src = window.URL.createObjectUrl(blob)
      })
    },
    checkURL(url) {
      if(url != '' && url != null && url != undefined){
        console.log("!!!!!!!!!!!!!!!!!!!!!URL");
        if (url.match(/\.(jpeg|jpg|gif|png)$/) != null){
          console.log("!!!!!!!!!!!!!!!!!!!!!!!Is Image");
          this.is_image = true
          this.loadImage(url);
        }else{
          console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!Is Video");
          this.is_image=false
          this.image_charge=true
          this.capture()
          // this.loadImagefromVideo(url)
        }
      }else{
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!No URL");
        console.log(url);
      }
    },
    loadImage(image){
      var img = new Image();
      img.src = image;
      img.onload = function() {
        this.src = image;
        this.image_charge = true;
      }.bind(this);
    },
    // loadImagefromVideo(video_url){
    //   var img = new Image();
    //   img.src = image;
    //   img.onload = function() {
    //     this.src = image;
    //     this.image_charge = true;
    //   }.bind(this);
    // },
  },
  props:{
    url: {
      type: String
    },
    event_id: {
      type: Number
    }
  },
  mounted(){
    this.checkURL(this.url);
  }
}
</script>
