import Vue from 'vue'
import VueResource from 'vue-resource'

Vue.use(VueResource)

// Vue.http.options.root = 'https://solutions.fusepong.com/api/';
// Vue.http.options.root = 'https://newsolutions.fusepong.com/api/';
// Vue.http.options.root = 'http://192.168.0.33:4000/api/'
Vue.http.options.root = 'https://staging.fusepong.com/api/';

Vue.http.headers.common['Access-Control-Allow-Origin'] = '*'
Vue.http.headers.common['Accept-Language'] = 'es'
Vue.http.headers.common['Access-Control-Allow-Methods'] = 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
Vue.http.headers.common['Access-Control-Allow-Headers'] = 'Origin, Content-Type, Authentication, Accept-Language'
Vue.http.headers['Access-Control-Max-Age'] = '1728000'
Vue.http.headers['Access-Control-Allow-Origin'] = '*'

console.log(Vue.http.headers);

Vue.http.interceptor.before = (request, next) => {
  console.log(request)
  next((response) => {
    console.log(response)
  })
}
