<template>
  <v-app id="inspire">
    <alert-modal v-if="getIsInAlert()"></alert-modal>
    <section class="new_login">
     <!-- :style="{'background-image': 'url(' + require('../../assets/img/background_pattern.png') + ')'}"> -->
      <div class="new_login__form_container login__login_content">
        <v-layout
          class="login_content__form"
          row wrap full-width>
          <img class="new_login__logo" :src="require('../../assets/img/logo_fuseaccess.png')" alt="">
          <div class="new_login__logo--title" style="color:#fff;">Central de Monitoreo</div>
          <p
            class="content_form__label"
            :class="{'error_text': error_email !== '' && user.email === ''}">
            Correo electrónico
          </p>
          <input
            type="text"
            class="content_form__input"
            placeholder="Escribe tu correo de acceso"
            v-model="user.email"
            :class="{'error_input': error_email !== '' && user.email === ''}">
          <p
            class="content_form__label"
            :class="{'error_text': error_pass !== '' && user.password === ''}">
            Contraseña
          </p>
          <input
            type="password"
            class="content_form__input"
            placeholder="Escribe tu contraseña"
            v-model="user.password"
            :class="{'error_input': error_pass !== '' && user.password === ''}">
          <h5
            v-if="loadingMessage.length>0"
            style="margin-left:20px;color:#2196f3;font-size:16px;position:relative;"><i>{{loadingMessage}}</i></h5>
          <v-spacer></v-spacer>
          <button
            class="content_form__button"
            :disabled="loadingMessage.length>0"
            style="border: none !important; font-size: 20px; font-weight: bold;"
            @click="isOnLine?signIn():offlineSignIn()">
            Ingresar
          </button>
          <a href="https://fuseaccess.com/" class="content_form__link no-underline" style="color:#fff;">
            2020 Fusepong. Todos los derechos reservados.
          </a>
        </v-layout>
      </div>
      <div class="new_login__visual_container">
        <section class="new_login__visual_container--principal_image">
          <img src="https://fusepongsolutions.s3.us-west-2.amazonaws.com/monitoring_central/monitoring_central.jpeg" alt="">
          <div class="new_login_background"></div>
          <guard-shield class="new_login_background__shield_1"></guard-shield>
          <guard-shield class="new_login_background__shield_2"></guard-shield>
          <guard-shield class="new_login_background__shield_3"></guard-shield>
          <guard-shield class="new_login_background__shield_4"></guard-shield>
          <guard-shield class="new_login_background__shield_5"></guard-shield>
        </section>
      </div>
      <div
        class="new_login__background_elements" >
        <img :src="require('../../assets/img/background_pattern.png')" alt="">
      </div>
    </section>
  </v-app>
</template>

<script>
  export default {
    data: () => ({
      drawer: true,
      user:{
        email: '',
        password: '',
        rut:''
      },
      error_email: '',
      error_pass: '',
      sublocations:'',
      colors:'',
      users:{
        "1015417203":{name:"Wilson Montoya",category:"One", access_doors:[1,2,3]},
        "90020854269":{name:"Andres Fonseca",category:"Premium",access_doors:[5,6,7,8]}
      },
      loadingMessage:'',
      isOnLine:true
    }),
    props: {
      source: String
    },
    methods:{
      signIn(){
        var self = this;
        try{
          if(this.user.email != "" && this.user.password != ""){
            var payload = {attributes: this.user}
            this.$http.post("users/sign_in", {
              data: this.encrypt(payload).toString()
            },{
              headers:{
                "X-Device-ID" : this.getDeviceId()
              }
            }).then( async function(response){
              console.log('========================> RESPONSE',response)
              if(response.body.meta.authentication_token != undefined &&
                response.body.meta.authentication_token != null &&
                response.body.meta.authentication_token != ''){
                if(response.body.meta.uuid != undefined &&
                  response.body.meta.uuid != null){
                  this.updateUserIdEncrypt(response.body.meta.uuid);
                }
                if(response.body.data.attributes.user_info_type=="monitor"){
                  self.updateUserToken(response.body.meta.authentication_token)
                  self.updateUserId(response.body.data.id)
                  self.updateUserType("monitor");
                  self.updateIsLogin(true);
                  self.updateEmail(response.body.data.attributes.email)
                  self.$router.push({name: 'monitoring'});
                  self.getUserMonitoredCompanies();
                }
              }
            }, function(response){
              if(response.body.errors && typeof response.body.errors[0].details != "object"){
                if(self.isDesktopApp){
                  self.updateAlertMessage(response.body.errors[0].details)
                  self.updateIsInAlert(true)
                }else{
                  alert(response.body.errors[0].details)
                }
                self.user.password=''
              }else{
                if(response.status==0){
                  this.isOnLine = false;
                  // this.offlineSignIn();
                }
                let message = []
                for(var key in response.body.errors[0].details){
                  message.push(response.body.errors[0].details[key])
                }
                if(self.isDesktopApp){
                  self.updateAlertMessage(message)
                  self.updateIsInAlert(true)
                }else{
                  alert(message)
                }
                self.user.password=''
              }
            });
          }else{
            if(self.isDesktopApp){
            self.updateAlertMessage('Por favor ingrese usuario y contraseña')
            self.updateIsInAlert(true)
            }else{
              if(this.user.password == ""){
                this.error_pass = 'Por favor ingrese la contraseña';
              }
              if(this.user.email == ""){
                this.error_email = 'Por favor ingrese el usuario';
              }
            }
          }
        }catch(e){
          alert(e.message);
        }
      },
      findCompanyInEvents(company){
        var vm = this;
        return (vm.getCurrentEvents().filter(event => event.company == company).length > 0)
      },
      getUserMonitoredCompanies(){
        try {
          this.$http.get('company_monitorings/get_user_monitored_companies',{
            params:{
              user_id: this.getUserId(),
            },
            headers: {
              "X-Device-ID" : this.getDeviceId(),
              // "Authorization" : 'Bearer ' + this.getUserToken()
            },
          })
          .then(function(response){
            this.updateSelectedCompany(null)
            let companies = []
            console.log('------------------> getUserMonitoredCompanies', response)
            response.body.forEach(data => {
              let filter_locations = []
              let locations = data.monitored_locations.sort((a, b) => a.pending_events - b.pending_events).reverse()
              locations.forEach(loc => {
                filter_locations.push({
                  id: loc.id,
                  name: loc.name,
                  pending_events: loc.pending_events,
                  sublocations: loc.sublocations,
                  new_event_validator: false,
                  speaker: loc.speaker
                })
              })
              companies.push({
                id: data.company.id,
                icon: 'apartment',
                name: data.company.name,
                route: 'monitoring',
                hikcentral_streaming_link: data.company.hikcentral_streaming_link,
                assigned_locations: filter_locations,
                monitored_locations: [],
                // pending_events: data.company.total_pending_events,
                pending_events: 0,
                monitoring_calls: data.company.monitoring_calls,
                dynamic_invitations: data.company.dynamic_invitations,
                remote_open_door: data.company.remote_open_door,
                remote_comunications: data.company.remote_comunications,
                perimetral_alerts: data.company.perimetral_alerts,
                new_event_validator: false
              });
            });
            let sortedCompaniesByEvents = companies.filter((company) => company.pending_events > 0).sort((a, b) => a.pending_events - b.pending_events).reverse().concat(companies.filter((company) => company.pending_events <= 0))
            this.updateMonitoredCompanies(sortedCompaniesByEvents)

            console.log("SortedCompaniesByEvents ", sortedCompaniesByEvents);
            // this.pages = response.body.meta.pages
            if(response.body.meta != undefined && response.body.meta != null){
              if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                this.checkToken(response.body.meta.authentication_token)
              }
            }
          }, function(response){
            if(response.body.meta != undefined && response.body.meta != null){
              if(response.body.meta.authentication_token != undefined && response.body.meta.authentication_token != null){
                this.checkToken(response.body.meta.authentication_token)
              }
            }
            console.log(response);
          })
        } catch (e) {
          alert(e.message)
        }
      },
    }
  }
</script>
