<template>
  <svg
    id="Capa_1"
    data-name="Capa 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 799 799">
    <path
      id="Path_20"
      data-name="Path 20"
      d="M0,399.5C0,178.86,178.86,0,399.5,0s399.5,178.86,399.5,399.5-178.86,399.5-399.5,399.5S0,620.14,0,399.5Z"
      style="fill: #B2DFDB; stroke-width: 0px;"/>
    <g
      id="Group_13"
      data-name="Group 13">
      <path
        id="Path_21"
        data-name="Path 21"
        d="M561.25,417.75c0-10.36-8.39-18.75-18.75-18.75s-18.75,8.39-18.75,18.75c.07,68.35-55.27,123.81-123.62,123.88-68.35.07-123.81-55.27-123.88-123.62v-.27c0-10.36-8.39-18.75-18.75-18.75s-18.75,8.39-18.75,18.75c.11,81.76,61.31,150.53,142.5,160.13v33.62h-65.62c-10.36,0-18.75,8.39-18.75,18.75s8.39,18.75,18.75,18.75h168.75c10.36,0,18.75-8.39,18.75-18.75s-8.39-18.75-18.75-18.75h-65.62v-33.62c81.19-9.6,142.39-78.38,142.5-160.13Z"
        style="fill: #343434; stroke-width: 0px;"/>
      <path
        id="Path_22"
        data-name="Path 22"
        d="M400,517.75c58.65-.07,106.18-47.6,106.25-106.25v-156.25c0-58.68-47.57-106.25-106.25-106.25s-106.25,47.57-106.25,106.25v156.25c.07,58.65,47.6,106.18,106.25,106.25ZM331.25,255.25c0-37.97,30.78-68.75,68.75-68.75s68.75,30.78,68.75,68.75h0v156.25c0,37.97-30.78,68.75-68.75,68.75s-68.75-30.78-68.75-68.75v-156.25Z"
        style="fill: #343434; stroke-width: 0px;"/>
      <path
        id="Path_23"
        data-name="Path 23"
        d="M400,292.75c10.36,0,18.74-8.41,18.73-18.77,0-4.96-1.98-9.72-5.48-13.23-7.45-6.99-19.05-6.99-26.5,0-7.32,7.33-7.31,19.2.02,26.52,3.51,3.51,8.27,5.48,13.23,5.48Z"
        style="fill: #343434; stroke-width: 0px;"/>
      <path
        id="Path_24"
        data-name="Path 24"
        d="M400,358.38c10.36,0,18.74-8.41,18.73-18.77,0-4.96-1.98-9.72-5.48-13.23-7.45-6.99-19.05-6.99-26.5,0-7.32,7.33-7.31,19.2.02,26.52,3.51,3.51,8.27,5.48,13.23,5.48Z"
        style="fill: #343434; stroke-width: 0px;"/>
      <path
        id="Path_25"
        data-name="Path 25"
        d="M400,424c10.36,0,18.74-8.41,18.73-18.77,0-4.96-1.98-9.72-5.48-13.23-7.45-6.99-19.05-6.99-26.5,0-7.32,7.33-7.31,19.2.02,26.52,3.51,3.51,8.27,5.48,13.23,5.48Z"
        style="fill: #343434; stroke-width: 0px;"/>
    </g>
  </svg>
</template>

<script>
export default {
  props:{
    color: {
      type: String,
      default: "#303F9F"
    }
  }
};
</script>

<style lang="css">
</style>
